import React, { useState, useEffect } from "react";

import "../App.css";
import data from "../data.json";
import { Form } from "react-bootstrap";
import Chatbot from "../assets/c1.png";
import { DatePicker } from "rsuite";
import moment from "moment";

const Appointment = (props) => {
  const data = props.datasource;

  //const [fields, setFields] = useState(["Name","age","company"]);
  const [visibleInputField, setVisibleInputField] = useState(true);
  let tzs = window.localStorage.getItem("tz");
  let offset = window.localStorage.getItem("offset");
  // const [fields, setFields] = useState([
  //   {
  //     fieldname: "name",
  //     fieldValue: "",
  //   },
  //   {
  //     fieldname: "age",
  //     fieldValue: "",
  //   },
  //   {
  //     fieldname: "company",
  //     fieldValue: "",
  //   },
  // ]);

  const [fields, setFields] = useState([]); // useState(data.form_data);

  const [fieldIndex, setFieldIndex] = useState(0);
  const [newData, setNewdata] = useState({});

  const partialObjectsArray = [];

  // Using forEach() to create partial objects
  // fields.forEach((obj) => {
  //   const partialObj = {};
  //   partialObj.form_field_name = obj.form_field_name;
  //   partialObj.field_values = "";
  //   // You can include other properties as needed
  //   partialObjectsArray.push(partialObj);
  // });

  // console.log({ partialObjectsArray });

  function onClick(evt) {
    setNewdata({
      [evt.target.name]: {
        value: evt.target.name,
      },
    });
  }

  const onDropdownValueChange = (e, index) => {
    let temporaryarray = fields.slice();
    // console.log(temporaryarray[index].field_values[0]);

    temporaryarray[index].selected_value = e.target.value;

    setFields(temporaryarray);
  };

  const onCheckboxValueChange = (e, index) => {
    let temporaryarray = fields.slice();
    temporaryarray[index].selected_value = e.target.checked;
    setFields(temporaryarray);
  };

  const onTextChange = (e, index) => {
    // const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    let temporaryarray = fields.slice();
    temporaryarray[index].selected_value = e.target.value;
    setFields(temporaryarray);
  };

  const onDateChange = (e, index) => {
    // console.log(moment(e).format("MM/DD/YYYY"));
    let a = moment(e).format("YYYY-MM-DD");
    // const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    let temporaryarray = fields.slice();
    temporaryarray[index].selected_value = a;
    setFields(temporaryarray);
  };

  const onTimeChange = (e, index) => {
    // console.log(moment(e).format("HH:mm"));
    let a = moment(e).format("HH:mm");
    // const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    let temporaryarray = fields.slice();
    temporaryarray[index].selected_value = a;
    setFields(temporaryarray);
  };

  const onEnterClick = (e, index) => {
    //onSubmit={handleFormSubmit} onDisplay={handleDisplayFormData}
    // if (e.key === "Enter") {
    // console.log("do validate");
    // console.log("Field Index: ", fldIndex);
    // console.log(e.target.value);
    //props.onStateChange(e.target.value);
    // props.onDisplay(e.target.value, "formGroup");

    // let temporaryarray = fields.slice();
    // temporaryarray[fldIndex].fieldValue = e.target.value;
    // setFields(temporaryarray);
    // e.target.value = "";
    // // if (fieldIndex == fields.length - 1) {
    // console.log("All fields are captured");
    // console.log(fields);
    // setVisibleInputField(false);
    // props.onSubmit(fields);
    // } else {
    // const nextFieldIndex = fieldIndex + 1;
    // setFieldIndex(nextFieldIndex);
    // props.onDisplay(fields[nextFieldIndex].fieldname, "form");
    //}
    //}
    // let data = [...fields];
    // data[index][e.target.form_field_name] = e.target.value;
    // setFields(data);

    // const values = e.target.value;
    // const newTodos = [...fields];
    // newTodos[index] = e.target.value;
    // setFields(newTodos);

    const values = [...partialObjectsArray];
    values[index].field_values = e.target.value;
    setNewdata(values);
  };

  useEffect(() => {
    const arrFields = [];
    data.form_data.forEach((field) => {
      let fld = {
        field_values: field.field_values,
        form_field_name: field.form_field_name,
        form_field_type: field.form_field_type,
        form_id: field.form_id,
        id: field.id,
        is_required: field.is_required,
        label: field.label,
        placeholder: field.placeholder,
        validation_rule: field.validation_rule,
        selected_value: field?.form_field_name == "timezone" ? tzs : "",
        isValid: true,
      };
      arrFields.push(fld);
    });
    setFields(arrFields);

    //updateState("Hi");
    //props.onDisplay(fields, "formGroup");
  }, []);

  // useEffect(() => {
  //   console.log("Calling useEffect");
  //   const arrFields = [];
  //   data.form_data.forEach((field) => {
  //     let fld = {
  //       field_values: field.field_values,
  //       form_field_name: field.form_field_name,
  //       form_field_type: field.form_field_type,
  //       form_id: field.form_id,
  //       id: field.id,
  //       is_required: field.is_required,
  //       label: field.label,
  //       placeholder: field.placeholder,
  //       validation_rule: field.validation_rule,
  //       selected_value: field?.form_field_type == "dropdown" && tzs,
  //       isValid: true,
  //     };
  //     arrFields.push(fld);
  //   });
  //   setFields(arrFields);
  //   console.log(fields);
  //   //updateState("Hi");
  //   //props.onDisplay(fields, "formGroup");
  // }, []);

  const updateState = (message, checker = "") => {
    //setMessages(oldArray => [...oldArray,message] );
    // setMessages((prev) => ({
    //   ...prev,
    //   messages: [...prev.messages, message]
    // }));
  };
  const [validated, setValidated] = useState(false);
  const submit = (e) => {
    e.preventDefault();
    //const form = e.currentTarget;
    if (conotrolValidation(fields) == true) {
      props.onSubmit(fields);
      setVisibleInputField(false);
    } else {
    }
    //console.log("VALIDATE FORM: ", form.checkValidity());
    /* if (form.checkValidity() === false) {
     // e.preventDefault();
      //e.stopPropagation();
    } else {
      setValidated(true);
      // let errors = {};
      // for (var i = 0; i < fields.length; i++) {
      //   if (fields[i].selected_value === "") {
      //     let inputName = fields[i].form_field_name;
      //     errors[inputName] = true;
      //   }
      // }
      // console.log("Fields on submit: ", errors);
      
    }*/
  };
  function conotrolValidation(formfields) {
    let bIsValid = true;

    let temporaryarray = formfields.slice();
    for (let i = 0; i < formfields.length; i++) {
      let field = formfields[i];
      if (field.form_field_type == "button") {
        //console.log(temporaryarray[index].field_values[0]);
        temporaryarray[i].isValid = true;
        temporaryarray[i].selected_value = "sent";
      }
      if (field.selected_value == "") {
        //console.log(temporaryarray[index].field_values[0]);
        temporaryarray[i].isValid = false;

        bIsValid = false;
      } else {
        temporaryarray[i].isValid = true;
      }
    }
    setFields(temporaryarray);

    return bIsValid ? true : false;
  }

  function disabledDate(current) {
    let customDate = moment().format("YYYY-MM-DD HH:mm");
    return current && current >= moment(customDate, "YYYY-MM-DD HH:mm");
  }

  return (
    <>
      {visibleInputField ? (
        <>
          <img
            style={{
              width: 30,
              float: "left",
              marginTop: 18,
            }}
            src={Chatbot}
            alt=""
          />
          <div
            className="chatbot_finish"
            // style={{
            //   display: !visibleInputField ? "none" : "block",
            //   // height: "75px",
            //   // marginLeft: "-303px",
            //   // backgroundColor: "red",
            // }}
          >
            <div className="chatbot_finish_inner">
              <div style={{ width: "100%" }}>
                {fields.map((item, index) => {
                  if (item.form_field_type == "text_area") {
                    return (
                      <div className="chat_finish1" key={index}>
                        <label>{item?.label}</label>
                        <textarea
                          required
                          type="text"
                          placeholder={item.placeholder}
                          //value={item.form_field_name}
                          //onChange={(e) => onEnterClick(e, index)}
                          onChange={(e) => onTextChange(e, index)}
                          // onKeyDown={(e) => onEnterClick(e, fieldIndex)}
                        ></textarea>
                        {!item.isValid && (
                          <label
                            style={{ color: "red" }}
                          >{`${item.placeholder}`}</label>
                        )}
                      </div>
                    );
                  }

                  if (item.form_field_type == "time") {
                    return (
                      <div key={index} className="chat_finish1 mb-3">
                        <label>{item.label}</label>
                        {/* <input
                          name={item.form_field_name}
                          required
                          type="time"
                          placeholder={item.placeholder}
                          // value={item.form_field_name}
                          //onChange={onClick}
                          onChange={(e) => onTextChange(e, index)}
                          // onKeyDown={(e) => onEnterClick(e, fieldIndex)}
                        ></input> */}
                        <DatePicker
                          className="time_picker"
                          // shouldDisableDate={disabledDate}
                          cleanable={false}
                          onOk={(e) => onTimeChange(e, index)}
                          // onSelect={(e) =>
                          //   console.log(moment(e).format("hh:mm"))
                          // }
                          editable={false}
                          block
                          format="HH:mm"
                          placeholder={"Choose Time"}
                          // oneTap
                        />
                        {!item.isValid && (
                          <label
                            style={{ color: "red", fontSize: 11 }}
                          >{`${item.placeholder}`}</label>
                        )}
                      </div>
                    );
                  }
                  if (item.form_field_type == "dropdown") {
                    let tz = JSON.parse(item.field_values);
                    return (
                      <div key={index} className="chat_finish1">
                        {/* <label>{item?.label}</label> */}
                        <select
                          defaultValue={tzs}
                          style={{ marginBottom: 2 }}
                          required
                          onChange={(e) => onDropdownValueChange(e, index)}
                        >
                          <option disabled>{item?.placeholder}</option>
                          {tz.map((i) => (
                            <>
                              <option
                                value={i.zone}
                              >{`${i.zone}  ${i.gmt}`}</option>
                            </>
                          ))}
                        </select>
                        {!item.isValid && (
                          <label
                            style={{ color: "red" }}
                          >{`${item.label}`}</label>
                        )}
                      </div>
                    );
                  }

                  if (item.form_field_type == "button") {
                    return (
                      <div style={{ textAlign: "center" }} key={index}>
                        <button type="submit" onClick={(e) => submit(e)}>
                          {item?.label}
                        </button>
                      </div>
                    );
                  }
                  return (
                    <div key={index} className="chat_finish1">
                      <label>{item.label}</label>
                      {/* <input
                        name={item.form_field_name}
                        required
                        type="date"
                        placeholder={item.placeholder}
                        // value={item.form_field_name}
                        //onChange={onClick}
                        onChange={(e) => onTextChange(e, index)}
                        // onKeyDown={(e) => onEnterClick(e, fieldIndex)}
                      ></input> */}
                      <DatePicker
                        className="date_picker"
                        // shouldDisableDate={disabledDate}
                        cleanable={false}
                        // onChange={(e) => console.log(e)}
                        onSelect={(e) => onDateChange(e, index)}
                        editable={false}
                        block
                        format="MM/dd/yyyy"
                        placeholder={"Choose Date"}
                        oneTap
                      />
                      {!item.isValid && (
                        <label
                          style={{ color: "red", fontSize: 11 }}
                        >{`${item.placeholder}`}</label>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Appointment;

const style3 = {
  height: "75px",
  marginLeft: "-303px",
  backgroundColor: "red",
};

import { OFFLINE_DATA, THREAD_MSG, CLEAR } from "./types";

let initialState = {
  mData: [],
  tData: [],
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case OFFLINE_DATA: {
      return { ...state, mData: action.payload };
    }
    case THREAD_MSG: {
      return { ...state, tData: action.payload };
    }
    case CLEAR: {
      return initialState;
    }
  }
  return state;
};

export default rootReducer;

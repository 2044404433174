import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import Chatbot from "../assets/c1.png";
import Userbot from "../assets/usericon.png";
import "../assets/style.css";
import { TypeAnimation } from "react-type-animation";
import moment from "moment";
import DotTypingAnimation from "./DotTypingAnimation";
import DOMPurify from "dompurify";
import { useSelector } from "react-redux";

const Message = (props) => {
  const [showChatTypingAnimation, setShowChatTypingAnimation] = useState(false);
  const divRef = useRef(null);
  let offline = useSelector((state) => state.mData);
  const [data, setData] = useState();
  //const [fields, setFields] = useState(["Name","age","company"]);
  const datasource = props.datasource;
  // console.log({ GROUP: data });
  // console.log({ A: data._MessageText });
  const onEnterClick = (e, fldIndex) => {
    // if (e.key === 'Enter') {
    //     console.log('do validate');
    //     console.log("Field Index: ",fldIndex)
    //     console.log(e.target.value);
    //     props.onStateChange(e.target.value);
    //     let temporaryarray = fields.slice();
    //     temporaryarray[fldIndex].fieldValue = e.target.value;
    //     setFields(temporaryarray);
    //     e.target.value='';
    //     if(fieldIndex==(fields.length-1)){
    //         console.log("All fields are captured");
    //         console.log(fields);
    //         setVisibleInputField(false);
    //     }
    //     else{
    //         setFieldIndex(fieldIndex + 1);
    //     }
    // }
  };

  const [DisplayResponse, setDisplayResponse] = useState();

  const messageLength = datasource?._MessageText?.length;
  // const animationDuration = `${Math.max(messageLength * 1000, 10000)}ms`;

  const animationDuration = messageLength * 21;

  useEffect(() => {
    //console.log("message received:", data);
    //updateState("Hi");
    if (datasource._MessageDirection == "left") {
      setShowChatTypingAnimation(true);
    }
    // if (props.callback) {
    setTimeout(() => {
      //data = props.datasource;
      setData(datasource);
      if (props.callback) {
        props.callback();
        // props.show(animationDuration);
      }
      setShowChatTypingAnimation(false);
    }, animationDuration);
    //}
  }, []);

  const listRef = useRef(null);
  useEffect(() => {
    listRef.current?.scrollIntoView({
      // block: "center",
      behavior: "smooth",
      // inline: "start",
    });
  }, [data]);

  const SafeHTML = DOMPurify.sanitize(datasource._MessageText);

  return (
    <>
      {data && (data._MessageType == "text" || data._MessageType == "form") && (
        <>
          <p
            ref={listRef}
            className={
              data._MessageDirection == "right" ? "right_chat" : "left_chat"
            }
          >
            {data._MessageDirection == "right" ? (
              <>
                <div className="indent_box1">
                  <span
                    className="indent"
                    key={data._MessageId}
                    dangerouslySetInnerHTML={{ __html: SafeHTML }}
                  >
                    {/* {data._MessageText} */}
                  </span>
                  <span className="indent_time">{data._MessageTime}</span>
                </div>
                <img src={Userbot} alt="" />
              </>
            ) : (
              <>
                <img src={Chatbot} alt="" />
                <div className="indent_box">
                  <span
                    className="indent"
                    key={data._MessageId}
                    dangerouslySetInnerHTML={{
                      __html: SafeHTML?.replace(
                        "[USER_NAME]",
                        window.localStorage
                          ?.getItem("user_name")
                          ?.split(" ")
                          ?.slice(0, 1)
                          ?.join("")
                      ).replace(
                        "[USER_EMAIL]",
                        window.localStorage
                          ?.getItem("user_email")
                          ?.split(" ")
                          ?.slice(0, 1)
                          ?.join("")
                      ),
                    }}
                  >
                    {/* {data._MessageText.replace(
                      "[USER_EMAIL]",
                      window.localStorage
                        .getItem("user_name")
                        .split(" ")
                        .slice(0, 1)
                        .join("")
                    )} */}
                  </span>
                  <span
                    style={{
                      fontSize: "10px",
                      float: "left",
                      paddingLeft: 14,
                      color: "#797980",
                    }}
                  >
                    {data._MessageTime}
                  </span>
                </div>
              </>
            )}
          </p>
        </>
      )}

      {data && data._MessageType == "attachment" && (
        <>
          <p ref={listRef} className="left_chat">
            <img src={Chatbot} alt="" />
            <div className="indent_box">
              <span
                className="indent"
                key={data._MessageId}
                dangerouslySetInnerHTML={{ __html: SafeHTML }}
              >
                {/* {data?._MessageText} */}
              </span>
              <span
                style={{
                  fontSize: "10px",
                  float: "left",
                  paddingLeft: 14,
                  color: "#797980",
                }}
              >
                {data._MessageTime}
              </span>
            </div>
          </p>
        </>
      )}

      {data && data._MessageType == "formgroup_msg" && (
        <>
          <p ref={listRef} className="left_chat">
            <img src={Chatbot} alt="" />
            <div className="indent_box">
              <span
                className="indent"
                key={data._MessageId}
                dangerouslySetInnerHTML={{ __html: SafeHTML }}
              >
                {/* {data?._MessageText} */}
              </span>
              <span
                style={{
                  fontSize: "10px",
                  float: "left",
                  paddingLeft: 14,
                  color: "#797980",
                }}
              >
                {data._MessageTime}
              </span>
            </div>
          </p>
        </>
      )}

      {data && data._MessageType == "appoint" && (
        <>
          <p ref={listRef} className="left_chat">
            <img src={Chatbot} alt="" />
            <div className="indent_box">
              <span
                className="indent"
                key={data._MessageId}
                dangerouslySetInnerHTML={{ __html: SafeHTML }}
              >
                {/* {data?._MessageText} */}
              </span>
              <span
                style={{
                  fontSize: "10px",
                  float: "left",
                  paddingLeft: 14,
                  color: "#797980",
                }}
              >
                {data._MessageTime}
              </span>
            </div>
          </p>
        </>
      )}

      {data && data._MessageType == "link" && (
        <p className="left_chat">
          <img src={Chatbot} alt="" />
          <div className="indent_box">
            <span
              style={{
                fontSize: "12px",
                float: "left",
                paddingLeft: 14,
                color: "#2d3436",
              }}
              className="indent"
            >
              {data._MessageContent}
              <br />
              <a
                style={{ wordWrap: "break-word" }}
                target="_blank"
                href={data._MessageText}
              >
                {data._MessageText}
              </a>
            </span>
            <span
              style={{
                fontSize: "10px",
                float: "left",
                paddingLeft: 14,
                color: "#797980",
              }}
            >
              {data._MessageTime}
            </span>
          </div>
        </p>
      )}

      {data && data._MessageType == "menu" && (
        <>
          <p
            ref={listRef}
            className={
              data._MessageDirection == "right" ? "right_chat" : "left_chat"
            }
          >
            {data._MessageDirection == "right" ? (
              <>
                <div className="indent_box1">
                  <span
                    className="indent"
                    dangerouslySetInnerHTML={{ __html: SafeHTML }}
                  >
                    {/* {data._MessageText} */}
                  </span>
                  <span className="indent_time">{data._MessageTime}</span>
                </div>
                <img src={Userbot} alt="" />
              </>
            ) : (
              <>
                <img src={Chatbot} alt="" />
                <div className="indent_box">
                  <span
                    className="indent"
                    dangerouslySetInnerHTML={{ __html: SafeHTML }}
                  >
                    {/* {data._MessageText} */}
                  </span>
                  <span
                    style={{
                      fontSize: "10px",
                      float: "left",
                      paddingLeft: 14,
                      color: "#797980",
                    }}
                  >
                    {data._MessageTime}
                  </span>
                </div>
              </>
            )}
          </p>
        </>
      )}

      {data && data._MessageType == "menuMsg" && (
        <>
          <p
            ref={listRef}
            className={
              data._MessageDirection == "right" ? "right_chat" : "left_chat"
            }
          >
            {data._MessageDirection == "right" ? (
              <>
                <div className="indent_box1">
                  <span
                    className="indent"
                    dangerouslySetInnerHTML={{ __html: SafeHTML }}
                  >
                    {/* {data._MessageText} */}
                  </span>
                  <span className="indent_time">{data._MessageTime}</span>
                </div>
                <img src={Userbot} alt="" />
              </>
            ) : (
              <>
                <img src={Chatbot} alt="" />
                <div className="indent_box">
                  <span
                    className="indent"
                    dangerouslySetInnerHTML={{ __html: SafeHTML }}
                  >
                    {/* {data._MessageText} */}
                  </span>
                  <span
                    style={{
                      fontSize: "10px",
                      float: "left",
                      paddingLeft: 14,
                      color: "#797980",
                    }}
                  >
                    {data._MessageTime}
                  </span>
                </div>
              </>
            )}
          </p>
        </>
      )}

      {data && data._MessageType == "formgroup" && (
        <>
          {/* <img style={{ width: 30, float: "right" }} src={Userbot} alt="" /> */}
          <div className="chatbot_finish chatbot_disabled">
            <div
              className="chatbot_finish_inner new_disable"
              style={{ background: "#718093" }}
            >
              <label
                className="mb-3"
                style={{ fontSize: 14, fontWeight: "bold", color: "#fff" }}
              >
                {data?._MessageObjRef[0]?.title}
              </label>
              <div style={{ width: "100%" }}>
                {data._MessageObjRef.map((item, index) => {
                  if (item.form_field_type == "file_upload") {
                    return (
                      <div className="chat_finish1" key={index}>
                        <label>{item?.form_field_name}</label>
                        <input
                          disabled
                          required
                          type="file"
                          value={item?.selected_value}
                        ></input>
                      </div>
                    );
                  }
                  if (item.form_field_type == "text_area") {
                    return (
                      <div key={index} className="chat_finish1">
                        <label style={{ color: "#fff" }}>{item?.label}</label>

                        <textarea
                          className="mt-0"
                          style={{ height: 40 }}
                          disabled
                          required
                          type="text"
                          value={item.selected_value}
                        ></textarea>
                      </div>
                    );
                  }
                  if (item.form_field_type == "checkbox") {
                    return (
                      <div key={index} className="chat_finish1">
                        <label style={{ color: "#fff" }} key={item?.label}>
                          <input
                            required
                            type="checkbox"
                            value={item.selected_value}
                          />
                          {item.label}
                        </label>
                      </div>
                    );
                  }
                  if (
                    item.form_field_type == "dropdown" &&
                    item.field_values !== null
                  ) {
                    return (
                      // <div key={index} className="chat_finish1">
                      //   <label>{item?.label}</label>
                      //   <select
                      //     disabled
                      //     style={{ marginBottom: 2 }}
                      //     required
                      //     value={item.selected_value}
                      //     defaultValue={item.selected_value}
                      //   >
                      //     <option selected disabled>
                      //       {item?.selected_value}
                      //     </option>
                      //   </select>
                      // </div>
                      <div key={index} className="chat_finish1">
                        <label style={{ color: "#fff" }}>{item.label}</label>

                        <input
                          disabled
                          name={item.form_field_name}
                          required
                          type="text"
                          value={item.selected_value}
                          //onChange={onClick}
                        ></input>
                      </div>
                    );
                  }
                  if (item.form_field_type == "button") {
                    return (
                      <></>
                      // <div style={{ textAlign: "center" }} key={index}>
                      //   <button type="submit" onClick={(e) => submit(e)}>
                      //     {item?.label}
                      //   </button>
                      // </div>
                    );
                  }
                  return (
                    <div key={index} className="chat_finish1">
                      <label style={{ color: "#fff" }}>{item.label}</label>
                      <input
                        disabled
                        name={item.form_field_name}
                        required
                        type="text"
                        value={item.selected_value}
                        //onChange={onClick}
                      ></input>
                    </div>

                    // <p
                    //   style={{
                    //     display:
                    //       item.form_field_type == "button" ? "none" : "block",
                    //   }}
                    // >
                    //   {/* {item.form_field_type !== "button" && item.label} */}
                    //   <span className="indent" style={{ paddingTop: 5 }}>
                    //     {item.form_field_type !== "button" && item.selected_value}
                    //   </span>
                    // </p>
                  );
                })}
              </div>
            </div>
            <span style={{ fontSize: "10px", float: "right" }}>
              {data._MessageTime}
            </span>
          </div>
        </>
      )}

      {showChatTypingAnimation && (
        <>
          <img style={{ height: "30px", width: "30px" }} src={Chatbot} alt="" />
          <div class="chat-bubble">
            <div class="typing">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
        </>
      )}

      {/* <p>
        <span className="indent" key={data._MessageId}>
          {data._MessageObjRef}
        </span>
      </p> */}
    </>
  );
};

export default Message;

const style3 = {
  height: "75px",

  backgroundColor: "red",
};

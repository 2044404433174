import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/style.css";
import Intro1 from "../assets/intro.png";
import moment from "moment";
function Intro() {
  const navigate = useNavigate();
  var client_id = "CL20240529001";
  let BASE_URL = process.env.REACT_APP_BASE_URL;
  const [tnc, setTnc] = useState();

  function check(e) {
    if (e == true) {
      setTnc(false);
    } else if (e == false) {
      setTnc(true);
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // event.preventDefault();
      // alert(event);
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    };

    // return () => {
    //   window.removeEventListener("beforeunload", handleBeforeUnload);
    // };
  }, []);

  window.addEventListener("beforeunload", (e) => {});
  // useEffect(() => {
  //     window.onbeforeunload = function() {

  //      return console.log('Refresh called')
  //         //return true;
  //     };

  //     return () => {
  //         window.onbeforeunload = null;
  //     };
  // }, []);

  // function unload() {
  //   // sessionStorage.removeItem("rowsCache");
  //   console.log('Refresh called')
  // }

  // window.onbeforeunload = unload;

  return (
    <>
      <div className="chat_head_sec intro_sec">
        <div className="row">
          <div className="col-sm-9 col-9"></div>
          <div className="col-sm-3 col-3">
            <div className="chat_head_rt">
              {/* <span>
                <i class="fa-solid fa-xmark"></i>
              </span> */}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="intro_logo">
              <div className="online">
                {/* <span className="green"></span> */}
                <img src={Intro1} alt="" />
                {/* <h3 style={{ color: "#fff" }}>Hi, I am Sourav</h3> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="chat_inner intro_sec1">
              <p className="left_chat">
                <h5>Hi, I am Savvy </h5>
                <span className="indent">
                  I am happy to help you today with your queries. Click on your
                  topic of queries on Technology Stack, Dedicated Hiring
                  services and much more. Welcome to quick and easy messaging
                  service!
                </span>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                paddingLeft: 11,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                value={tnc}
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 2,
                  marginRight: 8,
                  float: "left",
                  accentColor: "#8ea1b9",
                }}
                type="checkbox"
                onChange={(e) => setTnc(e.target.checked)}
              />
              <span
                style={{
                  fontSize: 12,
                  fontFamily: "Roboto,sans-serif",
                  float: "left",
                }}
              >
                I accept the{" "}
                <a
                  style={{ fontWeight: 600, color: "#718093" }}
                  target="_blank"
                  href="https://www.redappletech.com/privacy-policy/"
                >
                  Terms and Conditions.
                </a>
              </span>
            </div>
            <div className="btn_sec">
              <button
                style={{
                  background: !tnc ? "#ECEFF1" : "#8EA1B9",
                  color: !tnc ? "#71809359" : "#fff",
                }}
                disabled={!tnc ? true : false}
                className="start"
                onClick={() =>
                  navigate(
                    {
                      pathname: `/${client_id}`,
                    },
                    {
                      state: {
                        client_id: client_id,
                      },
                      replace: true,
                    }
                  )
                }
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Intro;

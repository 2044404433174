import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import ClearChat from "./ClearChat.js";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const EndChat = (props) => {
  let btnRef = useRef();
  //const [fields, setFields] = useState(["Name","age","company"]);
  const data = props.datasource;
  const [show, setShow] = useState(false);
  const [showUser, setShowUser] = useState(false);

  return (
    <>
      <div className="chat_final_pop">
        {/* <Form.Label>{props.name}</Form.Label> */}

        <div className="chat_menu final_box">
          <h6>
            <span key="menu_header">{props.name}</span>
          </h6>
          <div className="option">
            {props.disable ? null : (
              <span className="indent" onClick={props.yes}>
                Yes
              </span>
            )}
            <span className="indent" onClick={props.no}>
              No
            </span>
          </div>
        </div>

        {/* <div className="chat_menu">
          <h6>
            <span key="menu_header" style={{ fontSize: "100%" }}>
              {"Thank You.!!"}
            </span>
          </h6>
          <div className="option">
            <span className="indent" onClick={props.yes}>
              Yes
            </span>
            <span className="indent" onClick={props.no}>
              No
            </span>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default EndChat;

const style3 = {
  // height: "75px",
  // marginLeft: "-303px",
  // backgroundColor: "red",
};

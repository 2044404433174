import React, { useEffect, useState, useRef } from "react";
import Chatbot from "../../assets/c1.png";
import InteractiveForm from "../InteractiveForm";
import io from "socket.io-client";
import Userbot from "../../assets/usericon.png";
import Picker from "emoji-picker-react";
import moment from "moment";
import File from "../../assets/file.png";
import { FileUploader } from "react-drag-drop-files";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Printer from "../../assets/printer.png";
import lang from "../../assets/language.png";
import Clean from "../../assets/clean.png";
import End from "../../assets/end.png";
import EndChat from "../EndChat";
import { useIdle } from "react-use";
import { CLEAR } from "../../Redux/types";
import ClearChat from "../ClearChat";
import { useDispatch } from "react-redux";
import { Progress } from "rsuite";

const data = [
  {
    message_id: 1,
    request_id: 1,
    agent_user_id: 0,
    message: `Please see our blog\n`,
    url: "https://www.redappletech.com/our-portfolio/",
    created_at: moment().utcOffset("+05:30").toISOString(),
    contact_name: "satyajeet redapple",
    agent_name: null,
    sender_type: "agent",
    attachments: [],
  },
  // {
  //   message_id: 2,
  //   request_id: 2,
  //   agent_user_id: 0,
  //   message: "Please see our blog2",
  //   created_at: moment().utcOffset("+05:30").toISOString(),
  //   contact_name: "satyajeet redapple",
  //   agent_name: null,
  //   sender_type: "agent",
  //   attachments: [],
  // },
  // {
  //   message_id: 3,
  //   request_id: 3,
  //   agent_user_id: 0,
  //   message: "Please see our blog3",
  //   created_at: moment().utcOffset("+05:30").toISOString(),
  //   contact_name: "satyajeet redapple",
  //   agent_name: null,
  //   sender_type: "agent",
  //   attachments: [],
  // },
  // {
  //   message_id: 4,
  //   request_id: 4,
  //   agent_user_id: 0,
  //   message: "Please see our blog4",
  //   created_at: moment().utcOffset("+05:30").toISOString(),
  //   contact_name: "satyajeet redapple",
  //   agent_name: null,
  //   sender_type: "agent",
  //   attachments: [],
  // },
  // {
  //   message_id: 5,
  //   request_id: 5,
  //   agent_user_id: 0,
  //   message: "Please see our blog5",
  //   created_at: moment().utcOffset("+05:30").toISOString(),
  //   contact_name: "satyajeet redapple",
  //   agent_name: null,
  //   sender_type: "agent",
  //   attachments: [],
  // },
];

function ChatScreen() {
  const ref = useRef(null);
  let timerId;
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  let BASE_URL = process.env.REACT_APP_BASE_URL;
  let BASE_URL2 = process.env.REACT_APP_BASE_URL1;
  const [message, setMessage] = useState([]);
  const [sendMsg, setSendMsg] = useState("");
  const [req_id, setReq_id] = useState();
  const [contact_name, setContact_name] = useState("");
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [file, setFile] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [endFlag, setEndFlag] = useState(false);
  const [endManual, setEndManual] = useState(false);
  const [bar, setBar] = useState(false);
  const [glbEnd, setGlbEnd] = useState(false);
  const [end, setEnd] = useState(false);
  const user_email = window.localStorage.getItem("user_email");
  const [mail, setMail] = useState(user_email);
  const [load, setLoad] = useState(false);
  const [err, setErr] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const [threadItemIndex, setThreadItemIndex] = useState(0);
  const [stopRandomMsg, setStopRandomMsg] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [agent_name, setAgent_name] = useState("");
  const [agent_img, setAgent_img] = useState("");
  const [loading_file, setLoading_file] = useState(false);
  const [err2, setErr2] = useState("");
  const [agentOn, setAgentOn] = useState(false);
  const initialized = useRef(false);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => {
    setShow1(false);
    setAttachments([]);
    setShowProgress(false);
  };
  const handleClose = () => {
    setShow(!show);
  };
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleShow1 = () => setShow1(true);
  const fileTypes = ["JPG", "PNG", "GIF", "PDF"];

  const listRef = useRef(null);
  useEffect(() => {
    listRef.current?.scrollIntoView({
      block: "center",
      behavior: "smooth",
      // inline: "start",
    });
  }, [message]);

  const session_id = window.localStorage.getItem("session_id");
  // console.log({ session_id });
  let a = {
    chat_session_id: session_id,
  };
  const handleOpen = () => setOpen(!open);
  const socket = io("https://staginglocal.redappletech.com/chat", {
    path: "/hrms-socket/socket.io",
    //reconnection: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999,
    // transports: ["websocket"],
    // upgrade: false,
    // autoConnect: false,
    query: `auth_token=website-chatbot`,
  });

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      socket.on("connect", () => {
        console.log("Connected to server===>", socket.connected);
        socket.emit("chatbot:create-session", a);
      });

      setMessage((prev) => [
        ...prev,
        {
          message_id: "",
          request_id: "",
          agent_user_id: 0,
          message: "We are connecting to our agent. Please wait...",
          created_at: moment().utcOffset("+05:30").toISOString(),
          contact_name: "satyajeet redapple",
          agent_name: null,
          sender_type: "agent",
          attachments: [],
        },
      ]);

      // setInterval(() => {
      //   setMessage((prev) => [...prev, data[threadItemIndex]]);
      // }, 10000);
    }
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.on("chatbot:receive-session", (message) => {
      console.log("SessionReceive====>", message);
      setReq_id(message.data?.request_id);
      setContact_name(message.data?.contact_name);
      //   setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.off("chatbot:receive-session", (message) => {
        console.log(message);
        // setReq_id(message.data?.request_id);
      });
    };
    // socket.on("chatbot:session-closed", (message) => {
    //   console.log(message);
    //   setEndFlag(true);
    // });
    // socket.on("connection-failed", (data) => {
    //   console.log(data);
    // });
  }, []);

  useEffect(() => {
    socket.on("chatbot:session-closed", (message) => {
      console.log("SessionEnd====>", message);
      setMessage((prev) => [
        ...prev,
        {
          message: "Conversation ended.",
          created_at: moment().utcOffset("+05:30").toISOString(),
          contact_name: contact_name,
          agent_name: null,
          sender_type: "agent",
          attachments: [],
        },
      ]);
      setGlbEnd(true);
      setEndFlag(true);
    });
    return () => {
      socket.off("chatbot:session-closed", (message) => {
        setEndFlag(true);
      });
    };
  }, []);

  useEffect(() => {
    socket.on("chatbot:agent-online", (message) => {
      console.log("Agent online====>", message);
      // setEndFlag(true);
      setAgent_name(message?.data?.agent_name);
      setAgent_img(message?.data?.agent_profile_image);
      setMessage((prev) => [
        ...prev,
        {
          message: "You are now connected to our agent",
          created_at: moment().utcOffset("+05:30").toISOString(),
          contact_name: contact_name,
          agent_name: null,
          sender_type: "agent",
          attachments: [],
        },
      ]);
      setAgentOn(true);
    });
    return () => {
      socket.off("chatbot:agent-online", (message) => {
        // setEndFlag(true);
      });
    };
  }, []);

  useEffect(() => {
    socket.on("connection-failed", (data) => {
      console.log("ConnectionFailed=====>", data);
    });

    return () => {
      socket.off("connection-failed", (data) => {
        console.log(data);
      });
    };
  }, []);

  useEffect(() => {
    socket.on("chatbot:receive-message", (message) => {
      console.log("MsgReceive====>", message);
      clearTimeout(timerId);
      setMessage((prev) => [...prev, message.data]);
    });

    return () => {
      socket.off("chatbot:receive-message", (message) => {
        console.log(message);
        setMessage((prev) => [...prev, message.data]);
      });
    };
  }, []);

  useEffect(() => {
    if (threadItemIndex < data.length) {
      timerId = setTimeout(() => {
        setThreadItemIndex((prevIndex) => prevIndex + 1);
        //console.log(data[threadItemIndex]);
        setMessage((prev) => [...prev, data[threadItemIndex]]);
      }, 10000); // Adjust the delay time as needed

      return () => clearTimeout(timerId); // Cleanup timeout on component unmount
    }
  }, [threadItemIndex, data.length]);

  // const startTimer = () => {
  //   if (timerId) clearTimeout(timerId);
  //   timerId = setTimeout(() => {
  //     loadNextElement();
  //   }, 1000);
  // };

  // const stopTimer = () => clearTimeout(timerId);

  // useEffect(() => {

  //   startTimer();

  //   return stopTimer;
  // }, []);

  // useEffect(() => {
  //   timerId.current = setTimeout(() => {
  //     loadNextElement();
  //   }, 10000);
  //   return () => {
  //     // if (threadItemIndex == data.length) {
  //     clearTimeout(timerId.current);
  //     //}
  //   };
  // }, [message]);

  // useEffect(() => {
  //   loadNextElement();

  //   // return () => {
  //   //   loadNextElement();
  //   // };
  // }, []);

  const onPrint = async () => {
    setLoad(true);
    let session_id = window.localStorage.getItem("session_id");
    if (!mail) {
      setErr("Please fill your email.");
      setLoad(false);
      return;
    }
    let data = {
      to_mail: mail,
      user_session_id: session_id,
    };
    setErr("");
    try {
      await fetch(`${BASE_URL}chat-bot/send-email-chat-history`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          handleClose();
          setLoad(false);
          setMessage((prev) => [
            ...prev,
            {
              message: data.message,
              created_at: moment().utcOffset("+05:30").toISOString(),
              contact_name: contact_name,
              agent_name: null,
              sender_type: "agent",
              attachments: [],
            },
          ]);
        })
        .catch((e) => e.message);
    } catch (e) {
      throw e;
    }
  };

  const loadNextElement = () => {
    const nextIndex = threadItemIndex;
    console.log("ITEM INDEX: ", nextIndex);
    setThreadItemIndex(threadItemIndex + 1);
    let nextItem = data[nextIndex];
    if (threadItemIndex >= data.length - 1) {
      // console.log();
      // clearTimeout();
      // stopRandomMsg(true);
      clearTimeout(timerId.current);
      // clearInterval();
    } else {
      updateState(nextItem);
    }
  };

  const updateState = (message) => {
    console.log({ message });
    setMessage((prev) => [...prev, message]);
  };

  const sendMessage = () => {
    if (!sendMsg) {
      return;
    }

    socket.emit("chatbot:send-message", {
      request_id: req_id,
      message: sendMsg,
    });
    setSendMsg("");
  };

  const endChat = () => {
    socket.emit("chatbot:end-chat", {
      request_id: req_id,
    });
  };

  const onEnterClick = (e) => {
    if (e.key == "Enter") {
      if (!e.target.value) {
        return;
      } else {
        // setSendMsg(e.target.value);
        socket.emit("chatbot:send-message", {
          request_id: req_id,
          message: e.target.value,
        });
        setSendMsg("");
      }
    }
  };

  const getPresignedUrl = async (params, file) => {
    let response = await axios.post(
      "http://staginglocal.redappletech.com:5004/sales/get-chatbot-file-upload-url",
      params
    );
    const presignedUrl = response?.data?.data?.url;
    const presignedKey = response?.data?.data?.key;
    console.log(response?.data?.data);
    return { presignedUrl, presignedKey };
    //uploadToPresignedUrl(presignedUrl, file);
  };

  const generateUrl = async (file) => {
    console.log(file[0]?.name);
    let params = {
      request_id: req_id,
      filename: file[0]?.name,
    };

    let presigned = await getPresignedUrl(params, file);
    uploadToPresignedUrl(presigned.presignedUrl, file);
    console.log({ presigned });
  };

  const uploadToPresignedUrl = async (presignedUrl, file) => {
    console.log(file[0]?.type);
    let params = file;
    const uploadResponse = await axios.put(presignedUrl, file[0], {
      // method: "put",
      // maxBodyLength: Infinity,
      headers: {
        "Content-Type": file[0]?.type,
      },
      // data: params.blobFile,

      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        // setUploadProgress(percentCompleted);
        console.log(percentCompleted);
        if (percentCompleted == 100) {
          sendFile(file);
          // setFile(file.name);
          // if (edit == 0) {
          //   add_category(presignedKey);
          // } else {
          //   update_category(presignedKey);
          // }
        }
        //else {
        //   toast.error("image upload failed.");
        //   setTimeout(() => {
        //     toast.dismiss();
        //   }, 2000);
        // }
        //console.log(`Upload Progress: ${percentCompleted}%`);
      },
    });
    console.log({ uploadResponse });
  };

  const getPresignedUrls = async (params, file) => {
    let response = await axios.post(
      `${BASE_URL}chat-bot/get-upload-url`,
      params
    );
    const presignedUrl = response?.data?.data?.url;
    const presignedKey = response?.data?.data?.key;

    return { presignedUrl, presignedKey };
  };

  const generateUrls = async () => {
    setShowProgress(true);
    setLoading_file(true);
    if (!attachments.name) {
      setShowProgress(false);
      setLoading_file(false);
      // setErr("Please Choose file.");
      return;
    }
    // console.log(file.name);
    let params = {
      // request_id: req_id,
      filename: attachments.name,
    };

    let presigned = await getPresignedUrls(params, file);
    uploadToPresignedUrls(
      presigned.presignedUrl,
      presigned.presignedKey,
      attachments
    );
  };

  const uploadToPresignedUrls = async (presignedUrl, key, file) => {
    // console.log(file[0]?.type);
    // let params = file;
    const uploadResponse = await axios.put(presignedUrl, file, {
      // method: "put",
      // maxBodyLength: Infinity,
      headers: {
        "Content-Type": attachment.type,
      },
      // data: params.blobFile,

      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);

        if (percentCompleted == 100) {
          sendFiles(file);
          // setFile(file.name);
          // if (edit == 0) {
          //   add_category(presignedKey);
          // } else {
          //   update_category(presignedKey);
          // }
        }
        //else {
        //   toast.error("image upload failed.");
        //   setTimeout(() => {
        //     toast.dismiss();
        //   }, 2000);
        // }
        //console.log(`Upload Progress: ${percentCompleted}%`);
      },
    });
  };

  const sendFiles = (file) => {
    console.log(file?.name);
    let fileNameArray = [];

    fileNameArray.push(file?.name);

    console.log(fileNameArray);

    socket.emit("chatbot:send-message", {
      request_id: req_id,
      file_path: fileNameArray,
      message: sendMsg,
    });
    // setSendMsg("");
  };

  const sendFile = (file) => {
    console.log(file[0]?.name);
    let fileNameArray = [];

    fileNameArray.push(file[0]?.name);

    console.log(fileNameArray);

    socket.emit("chatbot:send-message", {
      request_id: req_id,
      file_path: fileNameArray,
      message: sendMsg,
    });
    // setSendMsg("");
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const isIdle = useIdle(300000);

  useEffect(() => {
    if (!glbEnd) {
      if (isIdle == true) {
        setEndFlag(true);
        setEndManual(true);
      }
    }
  }, [isIdle]);

  return (
    <>
      <div className="chat_mid">
        <div className="chat_mid_inner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="chat_head_sec">
                  <div className="row">
                    <div className="col-sm-9 col-9">
                      <div className="chat_head_lt">
                        <div className="online">
                          <span className="green"></span>
                          <img src={Chatbot} alt="" />
                        </div>
                        <h4>{"Red Apple Technologies"}</h4>
                        <span>We Are Online to Assist You</span>
                      </div>
                    </div>
                    <div className="col-sm-3 col-3">
                      <div className="chat_head_rt">
                        <Modal
                          className="modal_sec"
                          show={bar}
                          onHide={() => setBar(!bar)}
                        >
                          <Modal.Body>
                            <div className="bar_box">
                              <ul>
                                <li>
                                  <img src={lang} alt="" />
                                  Language{" "}
                                  <span
                                    style={{
                                      width: "auto",
                                      float: "right",
                                      fontWeight: 600,
                                    }}
                                  >
                                    English{" "}
                                    <i class="fa-solid fa-angle-down"></i>
                                  </span>
                                </li>
                                <li
                                  onClick={() => {
                                    handleShow();
                                    setBar(!bar);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={Printer} alt="" /> Print Transcript
                                </li>
                                <li
                                  onClick={() => {
                                    handleShow2();
                                    setBar(!bar);
                                  }}
                                >
                                  <img src={Clean} alt="" /> Clear History
                                </li>
                                <li
                                  onClick={() => {
                                    setEnd(true);
                                    setBar(!bar);
                                  }}
                                >
                                  <img src={End} /> End Conversation
                                </li>
                                <li
                                  // style={{
                                  //   display: !attach_status ? "none" : "block",
                                  // }}
                                  onClick={() => {
                                    handleShow1();
                                    setBar(!bar);
                                  }}
                                >
                                  <i class="fa-solid fa-paperclip"></i> Add
                                  Attachment
                                </li>
                              </ul>
                            </div>
                          </Modal.Body>
                        </Modal>

                        <span
                          style={{ paddingRight: 40 }}
                          onClick={() => setBar(!bar)}
                        >
                          <i class="fa-solid fa-bars"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chat_inner">
                  {message.map((item, key) => {
                    let k = moment(item.created_at).toLocaleString();
                    // console.log(moment(k).utc().local().format("hh:mm"));
                    return (
                      <>
                        {/* <p key={key}>{item.message}</p> */}
                        <p
                          ref={listRef}
                          className={
                            item.sender_type == "client"
                              ? "right_chat human"
                              : "left_chat "
                          }
                        >
                          {item.sender_type == "client" ? (
                            <>
                              <div ref={listRef} className="indent_box1">
                                <img
                                  // style={{ height: 100, width: 100 }}
                                  src={item?.attachments[0]?.file_path}
                                  alt=""
                                />

                                {item.message !== "" && (
                                  <span className="indent" key={key}>
                                    {item.message}
                                  </span>
                                )}

                                <span className="indent_time">
                                  {moment.utc(k).local().format("hh:mm a")}
                                  {/* {moment(item.created_at).format("hh:mm a")} */}
                                </span>
                              </div>

                              {/* <img src={Userbot} alt="" /> */}
                            </>
                          ) : (
                            <>
                              <img
                                src={!agent_img ? Chatbot : agent_img}
                                alt=""
                              />
                              <div className="indent_box">
                                <img
                                  className="bot"
                                  // style={{ height: 100, width: 100 }}
                                  src={item?.attachments[0]?.file_path}
                                  alt=""
                                />
                                {item.message !== "" && (
                                  <span
                                    style={{ whiteSpace: "pre-line" }}
                                    className="indent"
                                    key={key}
                                  >
                                    {item.message}
                                    <a href={item?.url} target="_blank">
                                      {item?.url}
                                    </a>
                                  </span>
                                )}

                                <span
                                  style={{
                                    fontSize: "10px",
                                    float: "left",
                                    paddingLeft: 14,
                                    color: "#797980",
                                    width: "100%",
                                  }}
                                >
                                  {moment(k).utc().local().format("hh:mm a")}
                                  {/* {moment(item.created_at).format("hh:mm a")} */}
                                </span>
                              </div>
                            </>
                          )}
                        </p>
                      </>
                    );
                  })}
                  {endManual ? (
                    <div>
                      <EndChat
                        name="Waiting too long. Is the information provided are useful?"
                        no={() => {
                          // setMenuForm(true);
                          setEndFlag(false);
                          setEndManual(false);
                          // setGlbEnd(false);
                        }}
                        yes={() => {
                          // onPrint();
                          setEndFlag(true);
                          setEnd(false);
                          setMessage((prev) => [
                            ...prev,
                            {
                              message: "Conversation ended.",
                              created_at: moment()
                                .utcOffset("+05:30")
                                .toISOString(),
                              contact_name: contact_name,
                              agent_name: null,
                              sender_type: "agent",
                              attachments: [],
                            },
                          ]);
                          onPrint();

                          setEndManual(false);
                          setGlbEnd(true);
                          // setMessages([]);
                        }}
                      />
                    </div>
                  ) : null}
                </div>

                {show2 ? (
                  <ClearChat
                    show={show2}
                    handleClose={() => handleClose2()}
                    name={"Are you sure, you want to clear your history?"}
                    no={() => handleClose2()}
                    yes={() => {
                      setMessage([]);
                      handleClose2();
                      window.location.reload();
                      window.localStorage.clear();
                      dispatch({
                        type: CLEAR,
                      });
                    }}
                  />
                ) : null}

                {end ? (
                  <ClearChat
                    show={end}
                    // handleClose={() => handleClose2()}
                    name={"Are you sure, you want to end your conversation?"}
                    no={() => setEnd(false)}
                    yes={() => {
                      endChat();
                      setMessage((prev) => [
                        ...prev,
                        {
                          message: "Conversation ended.",
                          created_at: moment()
                            .utcOffset("+05:30")
                            .toISOString(),
                          contact_name: contact_name,
                          agent_name: null,
                          sender_type: "agent",
                          attachments: [],
                        },
                      ]);
                      setGlbEnd(true);
                      setEndFlag(true);
                      setEnd(false);
                    }}
                  />
                ) : null}

                <Modal
                  className="attach_modal print_mod"
                  backdrop="static"
                  show={show}
                  onHide={handleClose}
                >
                  <div className="chat_final_pop">
                    <Modal.Header>
                      <Modal.Title>
                        <img src={Printer} alt="" /> Print Transcript
                      </Modal.Title>
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={handleClose}
                        class="fa-solid fa-xmark"
                      ></i>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                      <Form>
                        <Form.Group
                          className="mb-2"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            value={mail}
                            type="email"
                            placeholder="Enter your Email address"
                            onChange={(e) => setMail(e.target.value)}
                          />
                          <span
                            style={{
                              color: "red",
                              fontSize: 12,
                              paddingLeft: 11,
                              float: "left",
                            }}
                          >
                            {err}
                          </span>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer className="pt-0">
                      {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button> */}
                      <Button
                        // disabled={
                        //   !attach_status ? true : false || load ? true : false
                        // }
                        style={{
                          background: "#718093",
                          color: "#fff",
                          width: "100%",
                          borderRadius: 10,
                          paddingTop: 10,
                          paddingBottom: 10,
                          fontSize: 14,
                        }}
                        variant="primary"
                        onClick={() => {
                          onPrint();
                        }}
                      >
                        Submit Request
                      </Button>
                    </Modal.Footer>
                  </div>
                </Modal>

                <Modal
                  className="attach_modal print_mod"
                  backdrop="static"
                  show={show1}
                  onHide={handleClose1}
                >
                  <div className="chat_final_pop">
                    <Modal.Header>
                      <Modal.Title>
                        <img src={File} alt="" /> Add Attachment
                      </Modal.Title>
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={handleClose1}
                        class="fa-solid fa-xmark"
                      ></i>
                    </Modal.Header>
                    <Modal.Body style={{ paddingBottom: 0 }}>
                      <Form>
                        <Form.Group
                          className=""
                          controlId="exampleForm.ControlInput1"
                        >
                          {!showProgress && (
                            <div className="choose_sec drag_drop_sec">
                              <FileUploader
                                handleChange={(file) => setAttachments(file)}
                                // handleChange={(file) => generateUrl(file)}
                                name="file"
                                multiple={false}
                                types={fileTypes}
                              >
                                <p style={{ color: "#718093" }}>
                                  Drag Your File Here
                                  <br />
                                  Or
                                  <br />
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      color: "#718093",
                                      fontWeight: 500,
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Upload From Files
                                  </span>
                                </p>
                              </FileUploader>
                            </div>
                          )}
                          {showProgress && (
                            <Progress.Circle
                              style={{
                                display: "flex",
                                width: "50%",
                                height: "20%",
                                marginLeft: "25%",
                              }}
                              percent={uploadProgress}
                              status={
                                uploadProgress == 100 ? "success" : "active"
                              }
                            />
                          )}
                          {/* <span style={{ color: "#C63C51" }}>{err2}</span> */}
                          <span style={{ color: "#C63C51" }}>
                            {attachments.length !== 0 && "1 file selected."}
                          </span>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      {/* <Button variant="secondary" onClick={handleClose1}>
                    Close
                  </Button> */}
                      <Button
                        disabled={loading_file ? true : false}
                        style={{
                          background: "#718093", //!attach_status ? "#718093" : "#718093",
                          color: "#fff",
                          width: "100%",
                          borderRadius: 10,
                          paddingTop: 10,
                          paddingBottom: 10,
                          border: "none",
                        }}
                        variant="primary"
                        onClick={generateUrls}
                      >
                        Upload
                      </Button>
                    </Modal.Footer>
                  </div>
                </Modal>

                {agentOn && (
                  <div
                    style={{ display: endFlag ? "none" : "block" }}
                    className="chat_foot"
                  >
                    <div className="chat_foot_lt">
                      <input
                        ref={ref}
                        type="text"
                        value={sendMsg}
                        // style={{ color: errColor ? "red" : "#000" }}
                        placeholder={"Write something here..."}
                        onKeyDown={(e) => onEnterClick(e)}
                        onChange={(e) => {
                          setSendMsg(e.target.value);
                        }}
                      ></input>
                    </div>

                    <Picker
                      open={open}
                      onEmojiClick={
                        (e, emojiObject) => {
                          console.log(e.emoji);
                          const cursor = ref.current.selectionStart;
                          // let temp = message + e.emoji;
                          // setFields((fields)=>{...fields, temp});

                          setSendMsg(
                            (message) =>
                              message.slice(0, cursor) +
                              e.emoji +
                              message.slice(cursor)
                          );
                          // const newCursor = cursor + e.emoji.length;
                          // setTimeout(
                          //   () => ref.current.setSelectionRange(newCursor, newCursor),
                          //   10
                          // );
                        }

                        // setMessageForm((message) => message + e.emoji)
                      }
                      // onEmojiClick={(e, index) => console.log({ index })}
                      //onEmojiClick={onTextChange}
                      showPreview={false}
                      searchDisabled
                      skinTonesDisabled
                      previewConfig={{
                        showPreview: false,
                      }}
                    />

                    <input
                      style={{ display: "none" }}
                      type="file"
                      // id='attachmentsFile'
                      // name="attachmentsFile"
                      ref={inputFile}
                      // onChange={(file) => setAttachment(file)}
                      onChange={(e) => {
                        setAttachment(e.target.files);
                        setFile(e.target.files);
                        generateUrl(e.target.files);
                      }}
                      multiple
                      accept=".doc,.docx,.pdf,.xls,.xlsx,image/*"
                    />

                    <div className="chat_icons">
                      <div className="chat_icon_inner">
                        {/* <Smiley/> */}
                        <span
                          className="position-relative"
                          onClick={handleOpen}
                        >
                          <a>
                            <i class="fa-regular fa-face-smile"></i>
                          </a>
                        </span>
                        <span onClick={onButtonClick}>
                          <a>
                            <i class="fa-solid fa-paperclip"></i>
                          </a>
                        </span>
                        <span
                          // onClick={sendFile(attachment)}
                          onClick={sendMessage}
                        >
                          <a>
                            <i
                              style={{
                                color: !sendMsg ? "#DDDDDD" : "#527cb2",
                                cursor: sendMsg && "pointer",
                              }}
                              class="fa-solid fa-paper-plane"
                            ></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatScreen;

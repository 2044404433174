import React, { useState, useEffect } from "react";

import "../App.css";
import data from "../data.json";
import { Form } from "react-bootstrap";
import Chatbot from "../assets/c1.png";

const FormGroup = (props) => {
  const data = props.datasource;

  //const [fields, setFields] = useState(["Name","age","company"]);
  const [visibleInputField, setVisibleInputField] = useState(true);

  // const [fields, setFields] = useState([
  //   {
  //     fieldname: "name",
  //     fieldValue: "",
  //   },
  //   {
  //     fieldname: "age",
  //     fieldValue: "",
  //   },
  //   {
  //     fieldname: "company",
  //     fieldValue: "",
  //   },
  // ]);

  const [fields, setFields] = useState([]); // useState(data.form_data);

  const [fieldIndex, setFieldIndex] = useState(0);
  const [newData, setNewdata] = useState({});
  const [files, setFiles] = useState([]);

  const partialObjectsArray = [];

  // Using forEach() to create partial objects
  // fields.forEach((obj) => {
  //   const partialObj = {};
  //   partialObj.form_field_name = obj.form_field_name;
  //   partialObj.field_values = "";
  //   // You can include other properties as needed
  //   partialObjectsArray.push(partialObj);
  // });

  // console.log({ partialObjectsArray });

  function onClick(evt) {
    setNewdata({
      [evt.target.name]: {
        value: evt.target.name,
      },
    });
  }

  const onDropdownValueChange = (e, index) => {
    let temporaryarray = fields.slice();

    temporaryarray[index].selected_value = e.target.value;
    setFields(temporaryarray);
  };

  const onCheckboxValueChange = (e, index) => {
    let temporaryarray = fields.slice();
    temporaryarray[index].selected_value = e.target.checked;
    setFields(temporaryarray);
  };

  const onTextChange = (e, index) => {
    let temporaryarray = fields.slice();
    temporaryarray[index].selected_value = e.target.value;
    setFields(temporaryarray);
  };

  const onFileChange = (e, index) => {
    setFiles(e.target.files);

    let temporaryarray = fields.slice();
    temporaryarray[index].selected_value = e.target.files[0];
    setFields(temporaryarray);
  };

  const onEnterClick = (e, index) => {
    //onSubmit={handleFormSubmit} onDisplay={handleDisplayFormData}
    // if (e.key === "Enter") {
    // console.log("do validate");
    // console.log("Field Index: ", fldIndex);
    // console.log(e.target.value);
    //props.onStateChange(e.target.value);
    // props.onDisplay(e.target.value, "formGroup");

    // let temporaryarray = fields.slice();
    // temporaryarray[fldIndex].fieldValue = e.target.value;
    // setFields(temporaryarray);
    // e.target.value = "";
    // // if (fieldIndex == fields.length - 1) {
    // console.log("All fields are captured");
    // console.log(fields);
    // setVisibleInputField(false);
    // props.onSubmit(fields);
    // } else {
    // const nextFieldIndex = fieldIndex + 1;
    // setFieldIndex(nextFieldIndex);
    // props.onDisplay(fields[nextFieldIndex].fieldname, "form");
    //}
    //}
    // let data = [...fields];
    // data[index][e.target.form_field_name] = e.target.value;
    // setFields(data);

    // const values = e.target.value;
    // const newTodos = [...fields];
    // newTodos[index] = e.target.value;
    // setFields(newTodos);

    const values = [...partialObjectsArray];
    values[index].field_values = e.target.value;
    setNewdata(values);
  };

  useEffect(() => {
    const arrFields = [];
    data.form_data.forEach((field) => {
      let fld = {
        title: data.form_title,
        field_values: field.field_values,
        form_field_name: field.form_field_name,
        form_field_type: field.form_field_type,
        form_id: field.form_id,
        id: field.id,
        is_required: field.is_required,
        label: field.label,
        placeholder: field.placeholder,
        validation_rule: field.validation_rule,
        selected_value:
          field?.form_field_type == "button"
            ? "sent"
            : field?.form_field_type == "file_upload"
            ? {}
            : "",
        isValid: true,
      };
      arrFields.push(fld);
    });
    setFields(arrFields);

    //updateState("Hi");
    //props.onDisplay(fields, "formGroup");
  }, []);

  const updateState = (message, checker = "") => {
    //setMessages(oldArray => [...oldArray,message] );
    // setMessages((prev) => ({
    //   ...prev,
    //   messages: [...prev.messages, message]
    // }));
  };
  const [validated, setValidated] = useState(false);
  const submit = (e) => {
    e.preventDefault();
    //const form = e.currentTarget;
    if (conotrolValidation(fields) == true) {
      props.onSubmit(fields);
      setVisibleInputField(false);
    } else {
    }
    //console.log("VALIDATE FORM: ", form.checkValidity());
    /* if (form.checkValidity() === false) {
     // e.preventDefault();
      //e.stopPropagation();
    } else {
      setValidated(true);
      // let errors = {};
      // for (var i = 0; i < fields.length; i++) {
      //   if (fields[i].selected_value === "") {
      //     let inputName = fields[i].form_field_name;
      //     errors[inputName] = true;
      //   }
      // }
      // console.log("Fields on submit: ", errors);
      
    }*/
  };
  function conotrolValidation(formfields) {
    let bIsValid = true;

    let temporaryarray = formfields.slice();
    for (let i = 0; i < formfields.length; i++) {
      let field = formfields[i];
      if (field.selected_value.length == 0) {
        //console.log(temporaryarray[index].field_values[0]);
        temporaryarray[i].isValid = false;

        bIsValid = false;
      } else {
        temporaryarray[i].isValid = true;
      }
    }
    setFields(temporaryarray);

    return bIsValid ? true : false;
  }

  return (
    <>
      {visibleInputField ? (
        <>
          <img
            style={{
              width: 30,
              float: "left",
              marginTop: 18,
            }}
            src={Chatbot}
            alt=""
          />
          <div className="chatbot_finish">
            <div className="chatbot_finish_inner">
              <div style={{ width: "100%" }}>
                <label
                  className="mb-3"
                  style={{ fontSize: 14, fontWeight: "bold" }}
                >
                  {data?.form_title}
                </label>
                {fields.map((item, index) => {
                  if (item.form_field_type == "file_upload") {
                    return (
                      <div className="chat_finish1" key={index}>
                        <label>{item?.form_field_name}</label>
                        <input
                          value={files[0]?.name}
                          required
                          type="file"
                          placeholder={item.form_field_name}
                          //value={item.form_field_name}
                          onChange={(e) => onFileChange(e, index)}
                          // onKeyDown={(e) => onEnterClick(e, fieldIndex)}
                        ></input>
                        {!item.isValid && (
                          <label
                            style={{ color: "red" }}
                          >{`${item.form_field_name}`}</label>
                        )}
                      </div>
                    );
                  }
                  if (item.form_field_type == "text_area") {
                    return (
                      <div key={index} className="chat_finish1">
                        {/* <label>{item?.label}</label> */}
                        <textarea
                          required
                          type="text"
                          placeholder={item.placeholder}
                          //value={item.form_field_name}
                          //onChange={(e) => onEnterClick(e, index)}
                          onChange={(e) => onTextChange(e, index)}
                          // onKeyDown={(e) => onEnterClick(e, fieldIndex)}
                        ></textarea>
                        {!item.isValid && (
                          <label
                            style={{ color: "red" }}
                          >{`${item.placeholder}`}</label>
                        )}
                      </div>
                    );
                  }
                  if (item.form_field_type == "checkbox") {
                    return (
                      <div key={index} className="chat_finish1">
                        <label key={item?.label}>
                          <input
                            required
                            type="checkbox"
                            value="0"
                            onChange={(e) => onCheckboxValueChange(e, index)}
                          />
                          {item.label}
                        </label>
                        {!item.isValid && (
                          <label style={{ color: "red" }}>
                            Please select value
                          </label>
                        )}
                      </div>
                    );
                  }
                  if (
                    item.form_field_type == "dropdown" &&
                    item.field_values !== null
                  ) {
                    return (
                      <div key={index} className="chat_finish1">
                        {/* <label>{item?.label}</label> */}
                        <select
                          style={{ marginBottom: 2 }}
                          required
                          onChange={(e) => onDropdownValueChange(e, index)}
                        >
                          <option selected disabled>
                            {item?.placeholder}
                          </option>
                          {item.field_values.map((i) => (
                            <>
                              <option value={i}>{i}</option>
                            </>
                          ))}
                        </select>
                        {!item.isValid && (
                          <label
                            style={{ color: "red" }}
                          >{`${item.label}`}</label>
                        )}
                      </div>
                    );
                  }
                  if (item.form_field_type == "button") {
                    return (
                      <div style={{ textAlign: "center" }} key={index}>
                        <button type="submit" onClick={(e) => submit(e)}>
                          {item?.label}
                        </button>
                      </div>
                    );
                  }
                  return (
                    <div key={index} className="chat_finish1">
                      {/* <label>{item.label}</label> */}
                      <input
                        name={item.form_field_name}
                        required
                        type="text"
                        placeholder={item.placeholder}
                        // value={item.form_field_name}
                        //onChange={onClick}
                        onChange={(e) => onTextChange(e, index)}
                        // onKeyDown={(e) => onEnterClick(e, fieldIndex)}
                      ></input>
                      {!item.isValid && (
                        <label
                          style={{ color: "red", fontSize: 11 }}
                        >{`${item.placeholder}`}</label>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default FormGroup;

const style3 = {
  height: "75px",
  marginLeft: "-303px",
  backgroundColor: "red",
};

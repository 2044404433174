// import "./App.css";
import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ChatThread from "./components/ChatThread";
import Intro from "./components/Intro";
import RealChat from "./components/RealtimeChat/chatScreen";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import Endpage from "./components/Endpage";

function App() {
  const navigate = useNavigate();
  var loggedIn = true;
  var client_id = "CL20240529001";
  const exists = useSelector((state) => state.tData);
  const exists_msg = useSelector((state) => state.mData);

  const [searchParams] = useSearchParams();

  let page = searchParams.get("page_name"); // 10
  console.log({ page });

  useEffect(() => {
    if (exists_msg.length !== 0 || exists.length !== 0) {
      window.localStorage.clear();
      navigate(`/${client_id}`);
    } else {
      navigate(`/${client_id}/${page}`);
    }
  }, []);

  return (
    <>
      <Routes>
        <Route exact path="/:id/:name" element={<Intro />} />
        <Route path="/:id" element={<ChatThread />} />
        <Route path="/chat" element={<RealChat />}></Route>
        <Route path="/end_conversation" element={<Endpage />}></Route>
      </Routes>
    </>
  );
}

export default App;

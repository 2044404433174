import React, { useState, useEffect, useRef } from "react";

import "../App.css";
import data from "../data.json";
import Dropdown from "react-bootstrap/Dropdown";
import EmojiPicker from "emoji-picker-react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Attach from "../assets/attach.png";
import Smiley from "../components/Emojipanel";
import countryCode from "./county_code.json";
import File from "../assets/file.png";
import { FileUploader } from "react-drag-drop-files";

import Plane from "../assets/plane.png";

import Picker from "emoji-picker-react";
import axios from "axios";

const InteractiveForm = (props) => {
  const data = props.datasource;
  let BASE_URL = process.env.REACT_APP_BASE_URL;

  //const [fields, setFields] = useState(["Name","age","company"]);
  const [visibleInputField, setVisibleInputField] = useState(true);
  const [show, setShow] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [err, setErr] = useState("");
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [input, setInput] = useState("");
  const handleEmoji = () => setEmojiOpen(!emojiOpen);
  const [message, setMessageForm] = useState("");
  const [exist, setExist] = useState(false);
  const [errColor, setErrColor] = useState(false);

  const [prefix, setPrefix] = useState("");

  const handleClose = () => {
    setShow(false);
    setAttachment([]);
  };
  const ref = useRef(null);
  const fileTypes = ["JPG", "PNG", "GIF", "PDF"];
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  const handleShow = () => setShow(true);

  const [fields, setFields] = useState(data.form_data);
  const [fieldIndex, setFieldIndex] = useState(0);
  let attach_status = window.localStorage.getItem("attach_on");

  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let regName = /\s/g;
  var regexp = /.*\S.*/;
  let regPhone = /^(?:(\s*[\-]\s*)?|[0]?)?[123456789]\d{7,14}$/;
  // let regPhone = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{7,14}$/;
  let filter;

  let exists = false;
  const onEnterClick = (e, fldIndex) => {
    //onSubmit={handleFormSubmit} onDisplay={handleDisplayFormData}
    if (e.key === "Enter") {
      let tempArray = fields.slice();

      if (tempArray[fldIndex]?.form_field_name == "country_name") {
        filter = countryCode.filter(
          (country) =>
            country.name.toLowerCase() == e.target.value.toLowerCase() ||
            country.code.toLowerCase() == e.target.value.toLowerCase()
        );
        window.localStorage.setItem("calling_code", filter[0]?.dial_code);
        setPrefix(filter[0]?.dial_code);
      }

      if (!e.target.value) {
        return;
      } else if (
        tempArray[fldIndex]?.form_field_name == "user_name" &&
        !regexp.test(e.target.value)
      ) {
        return;
      } else if (
        tempArray[fldIndex]?.form_field_name == "user_email" &&
        !regEmail.test(e.target.value)
      ) {
        props.onDisplay(
          "Oops! I think your email is not valid 😟. Can you please recheck and provide a valid email?.",
          "form",
          "bot"
        );
        // setErrColor(true);
        return;
      } else if (
        tempArray[fldIndex]?.form_field_name == "country_name" &&
        !filter[0]?.dial_code
      ) {
        props.onDisplay(
          "Its look to me like an invalid country?.",
          "form",
          "bot"
        );
        return;
      } else if (
        tempArray[fldIndex]?.form_field_name == "user_phone" &&
        !regPhone.test(e.target.value)
      ) {
        props.onDisplay(
          "Its look to me like an invalid number. Can you please double check?",
          "form",
          "bot"
        );
        return;
      } else {
        //props.onStateChange(e.target.value);
        setMessageForm(e.target.value);
        props.onDisplay(e.target.value, "form", "user");

        let temporaryarray = fields.slice();
        temporaryarray[fldIndex].field_values = e.target.value;

        window.localStorage.setItem(
          `${temporaryarray[fldIndex]?.form_field_name}`,
          temporaryarray[fldIndex]?.field_values
        );

        setFields(temporaryarray);

        e.target.value = "";

        if (fieldIndex == fields.length - 1) {
          let newArr = fields.map(
            ({
              id,
              is_required,
              label,
              placeholder,
              validation_rule,
              form_field_type,
              field_order,
              ...rest
            }) => rest
          );
          let newFields = createObjectFromArray(newArr);

          //setVisibleInputField(false);
          props.onSubmit(newFields, newArr);
        } else {
          if (fields[fieldIndex].form_field_name == "user_email") {
            let params = {
              email: fields[fieldIndex].field_values,
            };
            axios
              .post(`${BASE_URL}chat-bot/check-previous-session`, params, {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                if (res.data.status == 0) {
                  props.onDisplay(
                    `Welcome back ${res?.data?.data?.data?.user_name
                      .split(" ")
                      ?.slice(0, 1)
                      ?.join("")}👋`,
                    "form",
                    "bot"
                  );
                  window.localStorage.setItem(
                    "user_name",
                    res?.data?.data?.data?.user_name
                  );
                  window.localStorage.setItem(
                    "user_email",
                    res?.data?.data?.data?.user_email
                  );
                  window.localStorage.setItem(
                    "session_id",
                    res?.data?.data?.data?.user_session_id
                  );
                  window.localStorage.setItem("attach_on", true);
                  setMessageForm("");
                  props.onStateChange(6);
                  // return;
                }
                if (res.data.status == 1) {
                  const nextFieldIndex = fieldIndex + 1;
                  setFieldIndex(nextFieldIndex);
                  props.onDisplay(fields[nextFieldIndex]?.label, "form", "bot");
                  setMessageForm("");
                }
              });
          } else if (fields[fieldIndex].form_field_name == "user_name") {
            const nextFieldIndex = fieldIndex + 1;
            setFieldIndex(nextFieldIndex);
            props.onDisplay(fields[nextFieldIndex]?.label, "form", "bot");
            setMessageForm("");
            // handleEmoji();
          } else if (fields[fieldIndex].form_field_name == "country_name") {
            const nextFieldIndex = fieldIndex + 1;
            setFieldIndex(nextFieldIndex);
            props.onDisplay(fields[nextFieldIndex]?.label, "form", "bot");
            setMessageForm("");
            // handleEmoji();
          }
        }
      }
    }
  };

  function createObjectFromArray(array) {
    return array.reduce((obj, item) => {
      obj[item.form_field_name] =
        item.form_field_name == "user_phone"
          ? prefix + item.field_values
          : item.field_values;
      return obj;
    }, {});
  }

  let country_code = window.localStorage.getItem("country_code");

  useEffect(() => {
    //updateState("Hi");
    props.onDisplay(
      fields[fieldIndex]?.form_field_type == "checkbox"
        ? fields[fieldIndex]?.form_field_name
        : fields[fieldIndex]?.label,
      "form",
      "bot"
    );
  }, []);

  const onTextChange = (e, index) => {
    // console.log("Text item changed: ", e, e.target.value, index);

    setMessageForm(e.target.value);

    //  setMessageForm(e.target.value);

    // e.target.value = "";
  };

  const onEmojiClick = (e, emojiObject) => {
    setChosenEmoji(e.emoji);

    // const cursor = ref.current.selectionStart;
    // const text = message.slice(0, cursor) + e.emoji + message.slice(cursor);
    // setMessageForm(text);

    // let sym = e.unified.split("-");
    // let codesArray = [];
    // sym.forEach((el) => codesArray.push("0x" + el));
    // let emoji = String.fromCodePoint(...codesArray);
    // setInput(input + emoji);
  };

  const onSubmit = () => {
    let filters;

    let temporaryarray = fields.slice();
    temporaryarray[fieldIndex].field_values = message;
    window.localStorage.setItem(
      `${temporaryarray[fieldIndex]?.form_field_name}`,
      temporaryarray[fieldIndex]?.field_values
    );

    if (fields[fieldIndex]?.form_field_name == "country_name") {
      filters = countryCode.filter(
        (country) =>
          country.name.toLowerCase() ==
            fields[fieldIndex].field_values.toLowerCase() ||
          country.code.toLowerCase() ==
            fields[fieldIndex].field_values.toLowerCase()
      );
      window.localStorage.setItem("calling_code", filters[0]?.dial_code);
      setPrefix(filters[0]?.dial_code);
    }
    setFields(temporaryarray);

    if (!fields[fieldIndex]?.field_values) {
      return;
    } else if (
      fields[fieldIndex]?.form_field_name == "user_name" &&
      !regexp.test(fields[fieldIndex].field_values)
    ) {
      return;
    } else if (
      fields[fieldIndex]?.form_field_name == "user_email" &&
      !regEmail.test(fields[fieldIndex].field_values)
    ) {
      props.onDisplay(
        "Oops! I think your email is not valid 😟. Can you please recheck and provide a valid email?.",
        "form",
        "bot"
      );
      return;
    } else if (
      fields[fieldIndex]?.form_field_name == "country_name" &&
      !filters[0]?.dial_code
    ) {
      props.onDisplay(
        "Its look to me like an invalid country?.",
        "form",
        "bot"
      );
      return;
    } else if (
      fields[fieldIndex]?.form_field_name == "user_phone" &&
      !regPhone.test(fields[fieldIndex].field_values)
    ) {
      props.onDisplay(
        "Its look to me like an invalid number. Can you please double check?",
        "form",
        "bot"
      );
      return;
    } else {
      // props.onDisplay(fields[fieldIndex]?.field_values, "form", "user");
      props.onDisplay(fields[fieldIndex]?.field_values, "form", "user");

      if (fieldIndex == fields.length - 1) {
        // console.log(fields);
        // setVisibleInputField(false);
        let newArr = fields.map(
          ({
            id,
            is_required,
            label,
            placeholder,
            validation_rule,
            form_field_type,
            field_order,
            ...rest
          }) => rest
        );
        let newFields = createObjectFromArray(newArr);
        props.onSubmit(newFields, newArr);
      } else {
        if (fields[fieldIndex].form_field_name == "user_email") {
          let params = {
            email: fields[fieldIndex].field_values,
          };
          axios
            .post(`${BASE_URL}chat-bot/check-previous-session`, params, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              if (res.data.status == 0) {
                props.onDisplay(
                  `Welcome back ${res?.data?.data?.data?.user_name
                    .split(" ")
                    ?.slice(0, 1)
                    ?.join("")}👋`,
                  "form",
                  "bot"
                );
                window.localStorage.setItem(
                  "user_email",
                  res?.data?.data?.data?.user_email
                );
                window.localStorage.setItem(
                  "session_id",
                  res?.data?.data?.data?.user_session_id
                );
                window.localStorage.setItem("attach_on", true);
                setMessageForm("");
                props.onStateChange(6);
                // return;
              }
              if (res.data.status == 1) {
                const nextFieldIndex = fieldIndex + 1;
                setFieldIndex(nextFieldIndex);
                props.onDisplay(fields[nextFieldIndex]?.label, "form", "bot");
                setMessageForm("");
              }
            });
        } else if (fields[fieldIndex].form_field_name == "user_name") {
          const nextFieldIndex = fieldIndex + 1;
          setFieldIndex(nextFieldIndex);
          props.onDisplay(fields[nextFieldIndex]?.label, "form", "bot");
          setMessageForm("");
          // handleEmoji();
        } else if (fields[fieldIndex].form_field_name == "country_name") {
          const nextFieldIndex = fieldIndex + 1;
          setFieldIndex(nextFieldIndex);
          props.onDisplay(fields[nextFieldIndex]?.label, "form", "bot");
          setMessageForm("");
          // handleEmoji();
        }

        // fields[fieldIndex].field_values = "";
        // const nextFieldIndex = fieldIndex + 1;
        // setFieldIndex(nextFieldIndex);
        // props.onDisplay(fields[nextFieldIndex]?.label, "form", "bot");
        // // fields[fieldIndex].field_values = "";
        // setMessageForm("");
      }
    }
  };
  const updateState = (message, checker = "") => {
    //setMessages(oldArray => [...oldArray,message] );
    // setMessages((prev) => ({
    //   ...prev,
    //   messages: [...prev.messages, message]
    // }));
  };

  const sendAttachment = async () => {
    if (attachment.length == 0) {
      setErr("please select attachment.");
      return;
    }
    let session_id = window.localStorage.getItem("session_id");
    let form = new FormData();
    form.append("user_session_id", session_id);
    form.append("files", attachment);
    try {
      await fetch(`${BASE_URL}chat-bot/add-user-session-attachment`, {
        method: "POST",
        body: form,
      })
        .then((res) => res.json())
        .then((data) => {
          // setMessages([]);
          // loadNextElement();
          handleClose();
        })
        .catch((e) => e.message);
    } catch (e) {
      throw e;
    }
  };

  let portfolio = window.localStorage.getItem("portfolio");
  let exportport = window.localStorage.getItem("user_email");

  return (
    <>
      <div className="chat_foot">
        {/* <Picker
          open={emojiOpen}
          onEmojiClick={onTextChange}
          // onEmojiClick={onEmojiClick}
          searchDisabled
          skinTonesDisabled
          previewConfig={{
            showPreview: false,
          }}
          height={350}
        /> */}
        {/* <div className="chat_foot_lt">
      <Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-basic">
       Language
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">English</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Hindi</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Urdu</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Espanol</Dropdown.Item>
        <Dropdown.Item href="#/action-3">France</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
      </div> */}
        <div
          className="chat_foot_lt"
          // style={{
          //   display: visibleInputField ? "block" : "none",
          //   // height: "75px",
          //   // marginLeft: "-303px",
          //   // backgroundColor: "red",
          // }}
        >
          {fields[fieldIndex]?.form_field_type == "text_input" && (
            <input
              ref={ref}
              type="text"
              value={message}
              // style={{ color: errColor ? "red" : "#000" }}
              placeholder={fields[fieldIndex]?.placeholder}
              onKeyDown={(e) => onEnterClick(e, fieldIndex)}
              onChange={(e) => {
                onTextChange(e, fieldIndex);
              }}
            ></input>
          )}
          {fields[fieldIndex]?.form_field_type == "tel" && (
            <>
              <span
                style={{
                  width: "16%",
                  float: "left",
                  fontSize: "13px",
                  height: "45px",
                  paddingTop: "14px",
                }}
              >
                {prefix}
              </span>
              <input
                autoFocus
                style={{
                  width: "83%",
                  float: "left",
                  border: "none",
                  fontSize: "13px",
                  paddingTop: "14px",
                }}
                ref={ref}
                type="tel"
                value={message}
                // style={{ display: visibleInputField ? "block" : "none" }}
                placeholder={fields[fieldIndex]?.placeholder}
                onKeyDown={(e) => onEnterClick(e, fieldIndex)}
                onChange={(e) => {
                  onTextChange(e, fieldIndex);
                }}
              ></input>
            </>
          )}

          {fields[fieldIndex]?.form_field_type == "checkbox" && (
            <input
              type="checkbox"
              style={{
                height: "50px",
                width: "50px",
                // display: visibleInputField ? "block" : "none",
              }}
              placeholder={fields[fieldIndex]?.form_field_name}
              onKeyDown={(e) => onEnterClick(e, fieldIndex)}
            ></input>
          )}
          <Modal
            className="attach_modal print_mod"
            backdrop="static"
            show={show}
            onHide={handleClose}
          >
            <div className="chat_final_pop">
              <Modal.Header>
                <Modal.Title>
                  <img src={File} alt="" /> Add Attachment
                </Modal.Title>
                <i
                  style={{ cursor: "pointer" }}
                  onClick={handleClose}
                  class="fa-solid fa-xmark"
                ></i>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlInput1"
                  >
                    <div className="choose_sec">
                      {/* <Form.Control
                      type="file"
                      onChange={(e) => setAttachment(e.target.files)}
                      // onChange={(e) => console.log(e.target.files)}
                    /> */}
                      <FileUploader
                        handleChange={(file) => setAttachment(file)}
                        name="file"
                        types={fileTypes}
                      >
                        <p>
                          Drag Your File Here
                          <br />
                          Or
                          <br />
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#718093",
                              fontWeight: 500,
                              textDecoration: "underline",
                            }}
                          >
                            Upload From Files
                          </span>
                        </p>
                      </FileUploader>
                      {/* <FileUploader
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                    /> */}
                    </div>
                    <span style={{ color: "red" }}>{attachment.name}</span>
                    <span style={{ color: "red" }}>{err}</span>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  disabled={!attach_status ? true : false}
                  style={{
                    background: !attach_status ? "#DDDDDD" : "#527cb2",
                    color: "#fff",
                    width: "100%",
                    borderRadius: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    border: "none",
                  }}
                  variant=""
                  onClick={sendAttachment}
                >
                  Send
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        </div>
        {emojiOpen && (
          <Picker
            onEmojiClick={
              (e, emojiObject) => {
                const cursor = ref.current.selectionStart;
                // let temp = message + e.emoji;
                // setFields((fields)=>{...fields, temp});

                setMessageForm(
                  (message) =>
                    message.slice(0, cursor) + e.emoji + message.slice(cursor)
                );
                // const newCursor = cursor + e.emoji.length;
                // setTimeout(
                //   () => ref.current.setSelectionRange(newCursor, newCursor),
                //   10
                // );
              }

              // setMessageForm((message) => message + e.emoji)
            }
            // onEmojiClick={(e, index) => console.log({ index })}
            //onEmojiClick={onTextChange}
            showPreview={false}
            searchDisabled
            skinTonesDisabled
            previewConfig={{
              showPreview: false,
            }}
          />
        )}

        <div className="chat_icons">
          <div className="chat_icon_inner">
            {/* <Smiley/> */}

            {/* <span
              style={{
                display: !attach_status ? "none" : "block",
              }}
              onClick={handleShow}
            >
              <a>
                <i class="fa-solid fa-paperclip"></i>
              </a>
            </span> */}
            <span className="position-relative" onClick={handleEmoji}>
              <a>
                <i
                  style={{ position: "absolute", top: "-6px", left: "-3px" }}
                  class="fa-regular fa-face-smile"
                ></i>
              </a>
            </span>
            <span onClick={onSubmit}>
              <a>
                <i
                  style={{
                    color: !message ? "#DDDDDD" : "#527cb2",
                    cursor: message && "pointer",
                  }}
                  class="fa-solid fa-paper-plane"
                ></i>
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InteractiveForm;

const style3 = {
  height: "75px",
  marginLeft: "-303px",
  backgroundColor: "red",
};

import React, { useState, useEffect, useRef } from "react";

// import "../App.css";
import data from "../data.json";
import InteractiveForm from "./InteractiveForm";
import Message from "./Message";
import Menu from "./Menu";
import Userbot from "../assets/usericon.png";
import FormGroup from "./FormGroup";
import Meeting from "./Meeting";
import EndChat from "./EndChat";
import Chatbot from "../assets/c1.png";
import Printer from "../assets/printer.png";
import File from "../assets/file.png";
import lang from "../assets/language.png";
import Clean from "../assets/clean.png";
import End from "../assets/end.png";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ClearChat from "./ClearChat";
import Attach from "../assets/attach.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Appointment from "./Appointment";
import InteractiveMenu from "./InteractiveMenu";
import InteractiveFormPortfolio from "./InteractiveFormPortfolio";
import { useIdle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR, OFFLINE_DATA, THREAD_MSG } from "../Redux/types";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { Progress } from "rsuite";
import { Uploader } from "rsuite";

export class ThreadMessage {
  _MessageId = Math.random();
  _MessageText = "";
  _MessageType = "";
  _MessageObjRef = null;
  _MessageDirection = "left";
  _MessageTime = "";
  _MessageContent = "";

  createFromObject(messageObj) {
    //this._MessageId=Math.random();
    this._MessageText = messageObj.content;
    this._MessageType = messageObj.content_type;
    this._MessageObjRef = messageObj;
  }
  create(msgText, msgType, msgObj, msgContent) {
    this._MessageText = msgText;
    this._MessageType = msgType;
    this._MessageObjRef = msgObj;
    this._MessageContent = msgContent;
  }
}

export class NewThreadMessage {
  _MessageId = Math.random();
  _MessageText = "";
  _MessageType = "";
  _MessageObjRef = null;
  _MessageDirection = "left";
  _MessageTime = "";
  _MessageContent = "";

  createFromObject(messageObj) {
    //this._MessageId=Math.random();
    this._MessageText = messageObj.content;
    this._MessageType = messageObj.content_type;
    this._MessageObjRef = messageObj;
  }
  create(msgText, msgType, msgObj, msgContent) {
    this._MessageText = msgText;
    this._MessageType = msgType;
    this._MessageObjRef = msgObj;
    this._MessageContent = msgContent;
  }
}

const ChatThread = () => {
  //JSON.parse(JSONData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const location = useLocation();
  let BASE_URL = process.env.REACT_APP_BASE_URL;
  let BASE_URL2 = process.env.REACT_APP_BASE_URL1;
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [clear, setClear] = useState(false);
  const [end, setEnd] = useState(false);
  const [endManual, setEndManual] = useState(false);
  const store_message = useSelector((state) => state.mData);
  const thread_msg = useSelector((state) => state.tData);
  const [endFlag, setEndflag] = useState(false);
  const [err, setErr] = useState("");
  const [menuForm, setMenuForm] = useState(false);
  const [endDisable, setEndDisable] = useState(false);
  const [load, setLoad] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [loading_file, setLoading_file] = useState(false);
  const [attachLoad, setAttachLoad] = useState(false);
  const [thread_err, setThread_err] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // console.log(thredItems);
  const [threadMessages, setThreadMessages] = useState([]);
  const [newThreadMessages, setNewThreadMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [data, setData] = useState([]);
  const [glbEnd, setGlbEnd] = useState(false);

  const fileTypes = ["JPG", "PNG", "GIF", "PDF"];
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  const thredItems = data;
  const [loadingData, setLoadingData] = useState(true);
  const [threadItemIndex, setThreadItemIndex] = useState(0);
  const initialized = useRef(false);
  const [pData, setPdata] = useState([]);
  const [tdata, setTData] = useState([]);
  const [contry, setContry] = useState("");
  const [ip, setIp] = useState("");
  const [networkD, setNetworkd] = useState({});
  const [tz, setTz] = useState("");
  const [offset, setOffset] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  var _id = "CL20240529001";
  // let a = window.localStorage.getItem("user_email");

  // useEffect(() => {
  //   const data = window.sessionStorage.getItem("persist");
  //   if (data !== null) setPdata(JSON.parse(data));
  // }, []);

  // useEffect(() => {
  //   const data = window.localStorage.getItem("persistT");
  //   if (data !== null) setTData(JSON.parse(data));
  // }, []);

  const [mail, setMail] = useState("");

  const closeButton = () => {
    const iframeElement = window.parent.document.getElementById("iframeId"); // Replace 'iframeId' with actual id of iframe in webpage2
    iframeElement.contentWindow.postMessage("ButtonClicked", "*");
  };

  const userDetails = async () => {
    await fetch("https://ipapi.co/json", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setContry(res.country_name);
        setIp(res.ip);
        setNetworkd(res);
        window.localStorage.setItem("tz", res.timezone);
        window.localStorage.setItem("offset", res.utc_offset);
        // setTz(res.timezone);
        // setOffset(res.utc_offset);
        let data = {
          client_id: _id,
          country: res?.country_name,
          ip_address: res?.ip,
          page_initiated_from: "contact us",
          network_details: JSON.stringify(res),
        };

        onValidate(data);
      });
  };

  const onValidate = async (data) => {
    try {
      await fetch(`${BASE_URL}chat-bot/create-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status == 0) {
            // console.log({ A: data.status });
            window.localStorage.setItem("session_id", data.data.sessionId);
            window.localStorage.setItem("client_id", data.data.clientId);
            window.localStorage.setItem("thread_id", data.data.threadId);
          }
        })
        .catch((e) => e.message);
    } catch (e) {
      throw e;
    }
  };

  let session_id = window.localStorage.getItem("session_id");

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      userDetails();

      // window.localStorage.clear();
    }
  }, []);

  useEffect(() => {
    getThreadMsg();
  }, []);

  const getThreadMsg = async () => {
    let data = {
      client_id: "CL20240529001",
    };
    try {
      await axios
        .post(`${BASE_URL}chat-bot/get-thread-items`, data)
        // await fetch(`${BASE_URL}chat-bot/get-thread-items`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },

        //   body: JSON.stringify(data),
        // })

        .then((result) => {
          if (result.data.status == 1) {
            //console.log("error");
            setThread_err(true);
          } else {
            //console.log(result.data);
            setData(result.data.data);
            updateState(result.data.data[threadItemIndex]);
            setThread_err(false);
          }
        })
        .catch((e) => e.message);

      // const result = await response.json();

      // dispatch({
      //   type: OFFLINE_DATA,
      //   payload: result.data[threadItemIndex],
      // });
      // console.log("Success thread===========>:", result.data[threadItemIndex]);
    } catch (e) {
      throw e;
    }
  };

  const loadNextElement = () => {
    const nextIndex = threadItemIndex + 1;
    //console.log("ITEM INDEX: ", nextIndex);
    setThreadItemIndex(threadItemIndex + 1);

    if (threadItemIndex >= thredItems.length - 1) {
    } else {
      //updateState(thredItems[threadItemIndex]);
      //console.log("Next Element:", thredItems[nextIndex]);
      const nextItem = thredItems[nextIndex];
      if (nextItem.content_type == "meeting_request") {
        const thMessage = new ThreadMessage();
        thMessage.create(nextItem.content, "meeting_request", nextItem);
        setThreadMessages((oldArray) => [...oldArray, thMessage]);
      } else {
        updateState(nextItem);

        dispatch({
          type: OFFLINE_DATA,
          payload: nextItem,
        });
        // setTimeout(() => {
        //   console.log("idle");
        // }, 10000);
      }
    }
    //console.log({ messages });
  };

  const handlePortfolioSubmit = (formData) => {
    setMenuForm(!menuForm);
    let session_id = window.localStorage.getItem("session_id");
    // setEndflag(true);
    let data = {
      to_mail: formData[0].field_values,
      user_session_id: session_id,
    };
    onPortfolioSubmit(data);
    // let newArr = formData.map(
    //   ({
    //     id,
    //     is_required,
    //     label,
    //     placeholder,
    //     validation_rule,
    //     form_id,
    //     form_field_type,
    //     ...rest
    //   }) => rest
    // );
    // let form_id = formData[0].form_id;
    // let updated = createObjectFromArray(newArr);
    // let form = new FormData();
    // form.append("formId", form_id);
    // form.append("clientId", 1);
    // form.append("formData", JSON.stringify(updated));
    // onFormSubmit(form);
  };

  const onPortfolioSubmit = async (data) => {
    try {
      await fetch(`${BASE_URL}chat-bot/send-email-portfolio`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          // setMessages([]);
          // loadNextElement();
          let a = moment().format("hh:mm a");
          const thMessage = new ThreadMessage();
          thMessage.create(data.message, "attachment");
          thMessage._MessageTime = a;
          thMessage._MessageDirection = "left";
          setThreadMessages((oldArray) => [...oldArray, thMessage]);
        })
        .catch((e) => e.message);
    } catch (e) {
      throw e;
    }
  };

  const onPrint = async () => {
    setLoad(true);
    let session_id = window.localStorage.getItem("session_id");
    if (!mail) {
      setErr("Please fill your email.");
      setLoad(false);
      return;
    }
    let data = {
      to_mail: mail,
      user_session_id: session_id,
    };
    setErr("");
    try {
      await fetch(`${BASE_URL}chat-bot/send-email-chat-history`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          // setMessages([]);
          // loadNextElement();
          handleClose();
          let a = moment().format("hh:mm a");
          const thMessage = new ThreadMessage();
          thMessage.create(data.message, "attachment");
          thMessage._MessageTime = a;
          thMessage._MessageDirection = "left";
          setThreadMessages((oldArray) => [...oldArray, thMessage]);
          setLoad(false);
          setMessages([]);
          //console.log("Success OnPrint===========>:", data);
        })
        .catch((e) => e.message);
    } catch (e) {
      throw e;
    }
  };

  const handleFormSubmit = (formData, id) => {
    // console.log("formData", formData, id);
    onContactFromSubmit(formData);
    // let newArr = formData.map(
    //   ({
    //     id,
    //     is_required,
    //     label,
    //     placeholder,
    //     validation_rule,
    //     form_id,
    //     form_field_type,
    //     ...rest
    //   }) => rest
    // );
    let form_id = id[0].form_id;
    // let updated = createObjectFromArray(newArr);
    let client_id = window.localStorage.getItem("client_id");
    let session_id = window.localStorage.getItem("session_id");
    let form = new FormData();
    form.append("formId", form_id);
    // form.append("clientId", client_id);
    form.append("user_session_id", session_id);
    form.append("formData", JSON.stringify(formData));
    onFormSubmit(form);
    setMenuForm(!menuForm);
    loadNextElement();
  };

  function createObjectFromArray(array) {
    return array.reduce((obj, item) => {
      obj[item.form_field_name] = item.field_values;
      return obj;
    }, {});
  }

  const onFormSubmit = async (form) => {
    let a = window.localStorage.getItem("user_email");
    try {
      await fetch(`${BASE_URL}chat-bot/submit-form`, {
        method: "POST",
        body: form,
      })
        .then((res) => res.json())
        .then((data) => {
          // setMessages([]);
          // loadNextElement();

          //console.log("Success thread===========>:", data);
          setMail(a);
          // dispatch({
          //   type: THREAD_MSG,
          //   payload: threadMessages,
          // });
          setThreadMessages([]);
          window.localStorage.setItem("attach_on", true);
        })
        .catch((e) => e.message);
    } catch (e) {
      throw e;
    }
  };

  const onFormGroupSubmit = async (form) => {
    try {
      await fetch(`${BASE_URL}chat-bot/submit-form`, {
        method: "POST",
        body: form,
      })
        .then((res) => res.json())
        .then((data) => {
          // setMessages([]);
          let a = moment().format("hh:mm a");
          const thMessage = new ThreadMessage();
          thMessage.create(
            "Thank you for submitting the form.",
            "formgroup_msg"
          );
          thMessage._MessageTime = a;
          thMessage._MessageDirection = "left";
          setThreadMessages((oldArray) => [...oldArray, thMessage]);
          // loadNextElement();
        })
        .catch((e) => e.message);
    } catch (e) {
      throw e;
    }
  };

  function createObjectFromArray(array) {
    return array.reduce((obj, item) => {
      obj[item.form_field_name] = item.field_values;
      return obj;
    }, {});
  }

  const onContactFromSubmit = async (formData) => {
    // console.log({ formData });
    const session_id = window.localStorage.getItem("session_id");

    let form = new FormData();
    form.append("name", formData.user_name);
    form.append("email", formData.user_email);
    form.append("phone_number", formData.user_phone);
    form.append("chat_session_id", session_id);
    form.append("page_details", "chat_bot");
    form.append("source_id", 8);
    form.append("country", contry);
    form.append("ip_address", ip);
    form.append("network_details", JSON.stringify(networkD));
    try {
      await fetch(`${BASE_URL2}sales/add-contact-web`, {
        method: "POST",
        body: form,
      })
        .then((res) => res.json())
        .then((data) => {
          // setMessages([]);
          // loadNextElement();
          window.localStorage.setItem("contact_id", data.data.contact_id);
          // console.log(
          //   "Success thread Contact===========>:",
          //   data.data.contact_id
          // );
        })
        .catch((e) => e.message);
    } catch (e) {
      throw e;
    }
  };

  const handleFormGroupSubmit = (formData) => {
    let newArr = formData.map(
      ({
        id,
        is_required,
        placeholder,
        validation_rule,
        form_id,
        field_values,
        ...rest
      }) => rest
    );
    let form_id = formData[0].form_id;
    let updated = createObjectFromGroup(newArr);
    let client_id = window.localStorage.getItem("client_id");
    let session_id = window.localStorage.getItem("session_id");
    let form = new FormData();
    form.append("formId", form_id);
    // form.append("clientId", client_id);
    form.append("user_session_id", session_id);
    form.append("formData", JSON.stringify(updated));

    let a = moment().format("hh:mm a");
    const thMessage = new ThreadMessage();
    thMessage.create("", "formgroup", newArr);
    thMessage._MessageTime = a;
    thMessage._MessageDirection = "right";
    setThreadMessages((oldArray) => [...oldArray, thMessage]);

    onFormGroupSubmit(form);
    // setMessages([]);
    // loadNextElement();
  };
  const [hideA, setHideA] = useState(false);

  const handleAppointmentFormSubmit = async (formData) => {
    let contact = window.localStorage.getItem("contact_id");
    let session_id = window.localStorage.getItem("session_id");
    let data = {
      user_session_id: session_id,
      appointment_date: formData[0].selected_value,
      appointment_time: formData[1].selected_value,
      timezone: formData[2].selected_value,
      appointment_message: formData[3].selected_value,
    };
    try {
      await fetch(`${BASE_URL2}sales/add-appointment-chatbot`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          // setMessages([]);
          // loadNextElement();

          setHideA(true);
          let a = moment().format("hh:mm a");
          const thMessage = new ThreadMessage();
          thMessage.create(data.message, "appoint");
          thMessage._MessageTime = a;
          thMessage._MessageDirection = "left";
          //setThreadMessages((oldArray) => [...oldArray, thMessage]);
          setThreadMessages((oldArray) => [...oldArray, thMessage]);
        })
        .catch((e) => e.message);
    } catch (e) {
      throw e;
    }
  };

  function createObjectFromGroup(array) {
    return array.reduce((obj, item) => {
      obj[item.form_field_name] = item.selected_value;
      return obj;
    }, {});
  }

  const handleDisplayFormData = (
    messageText,
    messageType,
    messageOwner,
    messageTime
  ) => {
    let a = moment().format("hh:mm a");
    const thMessage = new ThreadMessage();
    thMessage.create(messageText, messageType);
    thMessage._MessageTime = a;
    if (messageOwner == "user") {
      thMessage._MessageDirection = "right";
    }
    setThreadMessages((oldArray) => [...oldArray, thMessage]);

    // dispatch({
    //   type: THREAD_MSG,
    //   payload: threadMessages,
    // });
  };

  const handleDisplayAppointmentData = () => {};

  const handleDisplayFormGroupData = (messageText, messageType, msgObj) => {
    // const thMessage = new ThreadMessage();
    // thMessage.create(messageText, messageType, msgObj);
    // setThreadMessages((oldArray) => [...oldArray, thMessage]);
    // console.log("Thread Messages Group: ", threadMessages);
  };

  const handleMenuSubmit = (menuData) => {
    if (menuData?.menu_name == "Explore Portfolio") {
      window.localStorage.setItem("portfolio", true);
      setMenuForm(false);
    }

    // if(menuData?.menu == 'Career'){
    //   let a = moment().format("hh:mm a");
    // const thMessage = new ThreadMessage();
    // thMessage.create(menuData.menu_name, "menu");
    // thMessage._MessageTime = a;
    // thMessage._MessageDirection = "left";
    // //setThreadMessages((oldArray) => [...oldArray, thMessage]);
    // setThreadMessages((oldArray) => [...oldArray, thMessage]);
    // }

    let a = moment().format("hh:mm a");
    const thMessage = new ThreadMessage();
    thMessage.create(menuData.menu_name, "menu");
    thMessage._MessageTime = a;
    thMessage._MessageDirection = "right";
    //setThreadMessages((oldArray) => [...oldArray, thMessage]);
    setThreadMessages((oldArray) => [...oldArray, thMessage]);

    const index = thredItems.findIndex(
      (thredItems) => thredItems.id === menuData.action_item_id
    );

    updateState(thredItems[index]);
  };

  const handleEndChat = (menuData) => {
    if (menuData == 6) {
      const index = thredItems.findIndex(
        (thredItems) => thredItems.id === menuData
      );
      // console.log({ index });
      updateState(thredItems[index]);
    } else {
      window.localStorage.setItem("Onetime", true);
      let a = moment().format("hh:mm a");
      const thMessage = new ThreadMessage();
      thMessage.create("Thank you.", "menu");
      thMessage._MessageTime = a;
      thMessage._MessageDirection = "left";
      setThreadMessages((oldArray) => [...oldArray, thMessage]);
    }
  };

  const updateState = (message, checker = "") => {
    // const thMessage=new ThreadMessage()
    // thMessage.createFromObject(message);
    // console.log(thMessage);
    //setThreadMessages((oldArray) => [...oldArray, thMessage]);

    //setThreadMessages((oldArray) => [...oldArray, message]);

    setMessages([message]);
    // dispatch({
    //   type: OFFLINE_DATA,
    //   payload: message,
    // });

    // dispatch({
    //   type: THREAD_MSG,
    //   payload: threadMessages,
    // });
    // setThreadMessages((oldArray) => [...oldArray, message]);

    if (message.content_type == "text") {
      handleDisplayFormData(message.content, message.content_type);
    }
    if (message.content_type == "link") {
      handleDisplayCareer(
        message.meeting_link,
        message.content_type,
        "",
        message.content
      );
    }
    // dispatch({
    //   type: OFFLINE_DATA,
    //   payload: messages,
    // });
  };

  const handleDisplayCareer = (
    messageText,
    messageType,
    messageOwner,
    messageContent,
    messageTime
  ) => {
    let a = moment().format("hh:mm a");
    const thMessage = new ThreadMessage();
    thMessage.create(messageText, messageType, "", messageContent);
    thMessage._MessageTime = a;
    thMessage._MessageDirection = "right";
    setThreadMessages((oldArray) => [...oldArray, thMessage]);

    // dispatch({
    //   type: THREAD_MSG,
    //   payload: threadMessages,
    // });
  };

  useEffect(() => {
    dispatch({
      type: OFFLINE_DATA,
      payload: messages,
    });
  }, [messages]);

  useEffect(() => {
    dispatch({
      type: THREAD_MSG,
      payload: threadMessages,
    });
  }, [threadMessages]);

  // useEffect(() => {
  //   window.sessionStorage.setItem("persist", JSON.stringify(pData));
  // }, [pData]);

  // useEffect(() => {
  //   window.sessionStorage.setItem("persistT", JSON.stringify(threadMessages));
  // }, [tdata]);

  // console.log("PERSIST", pData);

  const handleStateChange = (value) => {
    let a = window.localStorage.getItem("user_email");

    let menuData = 6;
    const index = thredItems.findIndex((thredItems) => thredItems.id === value);
    // console.log({ index });
    setMail(a);
    // updateState(thredItems[index]);
    setMenuForm(!menuForm);
    // const thMessage = new ThreadMessage();
    // thMessage.create(menuForm, "menuMsg");
    // setThreadMessages((oldArray) => [...oldArray, thMessage]);
    //updateState(value);
    loadNextElement();
    //setParentState(value);
  };

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => {
    setShow1(false);
    setAttachment([]);
    setShowProgress(false);
  };
  const handleShow1 = () => setShow1(true);

  const [bar, setBar] = useState(false);
  const [err2, setErr2] = useState("");
  const [attachment, setAttachment] = useState([]);

  const sendAttachment = async (key) => {
    setLoading_file(true);
    if (attachment.length == 0) {
      setErr2("please select attachment.");
      setLoading_file(false);
      return;
    }
    setErr2("");
    setShowProgress(true);
    let session_id = window.localStorage.getItem("session_id");
    let params = {
      user_session_id: session_id,
      attachment: key,
    };
    try {
      await axios
        .post(`${BASE_URL}chat-bot/add-user-session-attachment`, params)
        .then((res) => {
          // setMessages([]);
          // loadNextElement();
          handleClose1();
          let a = moment().format("hh:mm a");
          const thMessage = new ThreadMessage();
          thMessage.create(res.data.message, "attachment");
          thMessage._MessageTime = a;
          thMessage._MessageDirection = "left";
          setThreadMessages((oldArray) => [...oldArray, thMessage]);

          setUploadProgress(0);
          setMessages([]);
          setLoading_file(false);
        })
        .catch((e) => e.message);
    } catch (e) {
      throw e;
    }
  };

  const listRef = useRef(null);
  const menuRef = useRef(null);
  useEffect(() => {
    listRef.current?.lastElementChild?.scrollIntoView({
      block: "center",
      behavior: "smooth",
      inline: "start",
    });
  }, [threadMessages]);

  useEffect(() => {
    menuRef.current?.lastElementChild?.scrollIntoView();
  }, [messages]);

  const handleMenuFormSubmit = (menuForm) => {
    setMenuForm(true);
    let menu = menuForm.toLowerCase();
    if (menu == "menu") {
      let menuData = 6;
      const index = thredItems.findIndex(
        (thredItems) => thredItems.id === menuData
      );
      // console.log({ index });
      updateState(thredItems[index]);
      let a = moment().format("hh:mm a");
      const thMessage = new ThreadMessage();
      thMessage.create(menuForm, "menuMsg");
      thMessage._MessageTime = a;
      thMessage._MessageDirection = "right";
      setThreadMessages((oldArray) => [...oldArray, thMessage]);
    } else {
      let a = moment().format("hh:mm a");
      const thMessage = new ThreadMessage();
      thMessage.create(
        'Nice to hear from you! Please type "Menu" to select options that you might be looking for!',
        "menuMsg"
      );
      thMessage._MessageTime = a;
      thMessage._MessageDirection = "left";
      setThreadMessages((oldArray) => [...oldArray, thMessage]);
      setMessages([]);
    }
  };

  const handleDisplayMenuFormData = () => {};

  const handleLinkSubmit = (data) => {
    // window.open(data);
  };

  const isIdle = useIdle(300000); //useIdle(300000);

  useEffect(() => {
    if (!glbEnd) {
      if (isIdle == true) {
        let a = moment().format("hh:mm a");
        // const thMessage = new ThreadMessage();
        // thMessage.create(
        //   "Waiting too long. Ending conversation",
        //   "menuMsg",
        //   "bot"
        // );
        // thMessage._MessageTime = a;
        // thMessage._MessageDirection = "left";
        // setThreadMessages((oldArray) => [...oldArray, thMessage]);
        setEndflag(true);
        setEndManual(true);

        // navigate("/end_conversation", { replace: true });
      }
    }

    // return () => {
    //   isIdleSet;
    // };
    // console.log({ isIdle });
  }, [isIdle]);

  // setIdleTimeout(
  //   5000,
  //   function () {
  //     document.body.innerText = "Where did you go?";
  //   },
  //   function () {
  //     document.body.innerText = "Welcome back!";
  //   }
  // );

  // function setIdleTimeout(millis, onIdle, onUnidle) {
  //   var timeout = 0;
  //   startTimer();

  //   function startTimer() {
  //     timeout = setTimeout(onExpires, millis);
  //     document.addEventListener("mousemove", onActivity);
  //     document.addEventListener("keydown", onActivity);
  //     document.addEventListener("touchstart", onActivity);
  //   }

  //   function onExpires() {
  //     timeout = 0;
  //     onIdle();
  //   }

  //   function onActivity() {
  //     if (timeout) clearTimeout(timeout);
  //     else onUnidle();
  //     //since the mouse is moving, we turn off our event hooks for 1 second
  //     document.removeEventListener("mousemove", onActivity);
  //     document.removeEventListener("keydown", onActivity);
  //     document.removeEventListener("touchstart", onActivity);
  //     setTimeout(startTimer, 1000);
  //   }
  // }

  const getPresignedUrl = async (params, file) => {
    let response = await axios.post(
      `${BASE_URL}chat-bot/get-upload-url`,
      params
    );
    const presignedUrl = response?.data?.data?.url;
    const presignedKey = response?.data?.data?.key;

    return { presignedUrl, presignedKey };
  };

  const generateUrl = async () => {
    setShowProgress(true);
    setLoading_file(true);
    if (!attachment.name) {
      setShowProgress(false);
      setLoading_file(false);
      // setErr2("Choose attachment file.");
      return;
    }

    // console.log(file.name);
    let params = {
      // request_id: req_id,
      filename: attachment.name,
    };

    let presigned = await getPresignedUrl(params, file);
    uploadToPresignedUrl(
      presigned.presignedUrl,
      presigned.presignedKey,
      attachment
    );
  };

  const uploadToPresignedUrl = async (presignedUrl, key, file) => {
    // console.log(file[0]?.type);
    // let params = file;
    const uploadResponse = await axios.put(presignedUrl, file, {
      // method: "put",
      // maxBodyLength: Infinity,
      headers: {
        "Content-Type": attachment.type,
      },
      // data: params.blobFile,

      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);

        if (percentCompleted == 100) {
          sendAttachment(key);
          // setFile(file.name);
          // if (edit == 0) {
          //   add_category(presignedKey);
          // } else {
          //   update_category(presignedKey);
          // }
        }
        //else {
        //   toast.error("image upload failed.");
        //   setTimeout(() => {
        //     toast.dismiss();
        //   }, 2000);
        // }
        //console.log(`Upload Progress: ${percentCompleted}%`);
      },
    });
  };

  let a = window.localStorage.getItem("persist");

  let attach_status = window.localStorage.getItem("attach_on");

  return (
    <div>
      <div className="chat_mid">
        <div className="chat_mid_inner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="chat_head_sec">
                  <div className="row">
                    <div className="col-sm-9 col-9">
                      <div className="chat_head_lt">
                        <div className="online">
                          <span className="green"></span>
                          <img src={Chatbot} alt="" />
                        </div>
                        <h4>{data[0]?.client_name}</h4>
                        <span>We Are Online to Assist You</span>
                      </div>
                    </div>
                    <div className="col-sm-3 col-3">
                      <div className="chat_head_rt">
                        <Modal
                          className="modal_sec"
                          show={bar}
                          onHide={() => setBar(!bar)}
                        >
                          <Modal.Body>
                            <div className="bar_box">
                              <ul>
                                <li>
                                  <img src={lang} alt="" />
                                  Language{" "}
                                  <span
                                    style={{
                                      width: "auto",
                                      float: "right",
                                      fontWeight: 600,
                                    }}
                                  >
                                    English{" "}
                                    <i class="fa-solid fa-angle-down"></i>
                                  </span>
                                </li>
                                <li
                                  onClick={() => {
                                    handleShow();
                                    setBar(!bar);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={Printer} alt="" /> Print Transcript
                                </li>
                                <li
                                  onClick={() => {
                                    handleShow2();
                                    setBar(!bar);
                                  }}
                                >
                                  <img src={Clean} alt="" /> Clear History
                                </li>
                                <li
                                  onClick={() => {
                                    setEnd(true);
                                    setBar(!bar);
                                  }}
                                >
                                  <img src={End} /> End Conversation
                                </li>
                                <li
                                  style={{
                                    display: !attach_status ? "none" : "block",
                                  }}
                                  onClick={() => {
                                    handleShow1();
                                    setBar(!bar);
                                  }}
                                >
                                  <i class="fa-solid fa-paperclip"></i> Add
                                  Attachment
                                </li>
                                {/* <li
                                  style={{
                                    display: !attach_status ? "none" : "block",
                                  }}
                                  onClick={() => {
                                    setBar(!bar);
                                    navigate({
                                      pathname: `/chat`,
                                    });
                                  }}
                                >
                                  <i class="fa-solid fa-user-tie"></i>
                                  Human Agent
                                </li> */}
                              </ul>
                            </div>
                          </Modal.Body>
                          {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
                        </Modal>

                        <span
                          style={{ paddingRight: 40 }}
                          onClick={() => setBar(!bar)}
                        >
                          <i class="fa-solid fa-bars"></i>
                        </span>

                        {/* <span onClick={() => closeButton()}>
                          <i class="fa-solid fa-xmark"></i>
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>

                {thread_err ? (
                  <>
                    <div
                      style={{
                        marginTop: "160px",
                        padding: "30px",
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      <p>
                        Chatbot is not currently available. We are sorry for
                        this inconvenience.
                        {/* {`Chatbot is not currently available. We are sorry for this inconvenience.\nYou can reach out to use by filling up the "Contact Us" form. We will respond back as soon as possible.`} */}
                      </p>
                      <br />
                      <span>
                        You can reach out to use by filling up the{" "}
                        <span style={{ fontWeight: "bold" }}>Contact Us</span>{" "}
                        form. We will respond back as soon as possible.
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="chat_inner">
                      {thread_msg?.map((item, index) => (
                        <>
                          {item?._MessageType == "text" && (
                            <div
                            // ref={listRef}
                            >
                              <Message
                                datasource={item}
                                callback={loadNextElement}
                                // show={(e) => console.log("TIME", e)}
                              />
                            </div>
                          )}

                          {item?._MessageType == "meeting_request" && (
                            <Message
                              datasource={item}
                              callback={loadNextElement}
                            />
                          )}

                          {item?._MessageType == "form" && (
                            <Message datasource={item} callback={null} />
                          )}

                          {item?._MessageType == "formgroup" && (
                            <Message datasource={item} callback={null} />
                          )}
                          {item?._MessageType == "menu" && (
                            <Message datasource={item} callback={null} />
                          )}
                          {item?._MessageType == "menuMsg" && (
                            <Message datasource={item} callback={null} />
                          )}
                          {item?._MessageType == "appoint" && (
                            <Message datasource={item} callback={null} />
                          )}
                          {item?._MessageType == "attachment" && (
                            <Message datasource={item} callback={null} />
                          )}
                          {item?._MessageType == "formgroup_msg" && (
                            <Message datasource={item} callback={null} />
                          )}
                          {item?._MessageType == "link" && (
                            <Message datasource={item} callback={null} />
                          )}
                        </>
                      ))}

                      {messages?.map((item, index) => {
                        // console.log({ item });
                        return (
                          <>
                            {item?.content_type == "menu" && (
                              <div>
                                {!endFlag ? (
                                  <>
                                    <img
                                      style={{ float: "left", width: 30 }}
                                      src={Chatbot}
                                      alt=""
                                    />

                                    <Menu
                                      datasource={item}
                                      callback={handleMenuSubmit}
                                    />
                                  </>
                                ) : null}
                              </div>
                            )}
                            {/* 
                        {item?.content_type == "link" && (
                          <div>
                            <Meeting
                              datasource={item}
                              callback={handleLinkSubmit}
                            />
                          </div>
                        )} */}

                            {item?.content_type == "form" &&
                              item?.form_type == "formgroup" && (
                                <div>
                                  {/* <img
                                style={{
                                  width: 30,
                                  float: "left",
                                  marginTop: 18,
                                }}
                                src={Chatbot}
                                alt=""
                              /> */}
                                  <FormGroup
                                    datasource={item}
                                    onSubmit={handleFormGroupSubmit}
                                    onDisplay={handleDisplayFormGroupData}
                                  />
                                </div>
                              )}
                            {/* {item.content_type == "end_chat" && (
                          <EndChat datasource={item} callback={handleEndChat} />
                        )} */}
                            {item?.content_type == "appointment" && (
                              <div>
                                {/* <img
                              style={{
                                width: 30,
                                float: "left",
                                marginTop: 18,
                              }}
                              src={Chatbot}
                              alt=""
                            /> */}
                                <Appointment
                                  datasource={item}
                                  onSubmit={handleAppointmentFormSubmit}
                                  onDisplay={handleDisplayAppointmentData}
                                />
                              </div>
                            )}
                          </>
                        );
                      })}
                      {endManual ? (
                        <div>
                          <EndChat
                            name="Waiting too long. Is the information provided are useful?"
                            no={() => {
                              // setMenuForm(true);
                              setEndflag(false);
                              setEndManual(false);
                              // setGlbEnd(false);
                            }}
                            yes={() => {
                              onPrint();
                              setEndflag(true);
                              setEnd(false);
                              let a = moment().format("hh:mm a");
                              const thMessage = new ThreadMessage();
                              thMessage.create(
                                "Conversation ended.",
                                "menuMsg",
                                "bot"
                              );
                              thMessage._MessageTime = a;
                              thMessage._MessageDirection = "left";
                              setThreadMessages((oldArray) => [
                                ...oldArray,
                                thMessage,
                              ]);
                              setEndManual(false);
                              setGlbEnd(true);
                              setMessages([]);
                            }}
                          />
                        </div>
                      ) : null}
                    </div>

                    <div>
                      {messages?.map((item, index) => {
                        return (
                          <>
                            <div>
                              {!endFlag ? (
                                <>
                                  {item?.content_type == "form" &&
                                    item?.form_type == "interactive" && (
                                      <InteractiveForm
                                        datasource={item}
                                        onSubmit={handleFormSubmit}
                                        onDisplay={handleDisplayFormData}
                                        onStateChange={handleStateChange}
                                        // onStateChange={loadNextElement}
                                      />
                                    )}
                                </>
                              ) : null}

                              {item?.content_type == "email_portoflio" &&
                                item?.form_type == "interactive" && (
                                  <InteractiveFormPortfolio
                                    datasource={item}
                                    onSubmit={handlePortfolioSubmit}
                                    onDisplay={handleDisplayFormData}
                                    onStateChange={handleMenuFormSubmit}
                                  />
                                )}
                            </div>
                          </>
                        );
                      })}
                      {menuForm ? (
                        <>
                          {!endFlag && (
                            <InteractiveMenu
                              //datasource={item}
                              onSubmit={handleMenuFormSubmit}
                              onDisplay={handleDisplayMenuFormData}
                            />
                          )}
                        </>
                      ) : null}
                    </div>
                  </>
                )}

                <Modal
                  className="attach_modal print_mod"
                  backdrop="static"
                  show={show}
                  onHide={handleClose}
                >
                  <div className="chat_final_pop">
                    <Modal.Header>
                      <Modal.Title>
                        <img src={Printer} alt="" /> Print Transcript
                      </Modal.Title>
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={handleClose}
                        class="fa-solid fa-xmark"
                      ></i>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                      <Form>
                        <Form.Group
                          className="mb-2"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            value={mail}
                            type="email"
                            placeholder="Enter your Email address"
                            onChange={(e) => setMail(e.target.value)}
                          />
                          <span
                            style={{
                              color: "red",
                              fontSize: 12,
                              paddingLeft: 11,
                              float: "left",
                            }}
                          >
                            {err}
                          </span>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer style={{ marginTop: 20 }} className="pt-0">
                      {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button> */}
                      <Button
                        disabled={
                          !attach_status ? true : false || load ? true : false
                        }
                        style={{
                          background: "#718093",
                          color: "#fff",
                          width: "100%",
                          borderRadius: 10,
                          paddingTop: 10,
                          paddingBottom: 10,
                          fontSize: 14,
                        }}
                        variant="primary"
                        onClick={() => {
                          onPrint();
                        }}
                      >
                        Submit Request
                      </Button>
                    </Modal.Footer>
                  </div>
                </Modal>

                <Modal
                  className="attach_modal print_mod"
                  backdrop="static"
                  show={show1}
                  onHide={handleClose1}
                >
                  <div className="chat_final_pop">
                    <Modal.Header>
                      <Modal.Title>
                        <img src={File} alt="" /> Add Attachment
                      </Modal.Title>
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={handleClose1}
                        class="fa-solid fa-xmark"
                      ></i>
                    </Modal.Header>
                    <Modal.Body style={{ paddingBottom: 0 }}>
                      <Form>
                        <Form.Group
                          className=""
                          controlId="exampleForm.ControlInput1"
                        >
                          {!showProgress && (
                            <div className="choose_sec drag_drop_sec">
                              <FileUploader
                                handleChange={(file) => setAttachment(file)}
                                // handleChange={(file) => generateUrl(file)}
                                name="file"
                                multiple={false}
                                types={fileTypes}
                              >
                                <p style={{ color: "#718093" }}>
                                  Drag Your File Here
                                  <br />
                                  Or
                                  <br />
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      color: "#718093",
                                      fontWeight: 500,
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Upload From Files
                                  </span>
                                </p>
                              </FileUploader>
                            </div>
                          )}
                          {showProgress && (
                            <Progress.Circle
                              style={{
                                display: "flex",
                                width: "50%",
                                height: "20%",
                                marginLeft: "25%",
                              }}
                              percent={uploadProgress}
                              status={
                                uploadProgress == 100 ? "success" : "active"
                              }
                            />
                          )}
                          {/* <span style={{ color: "#C63C51" }}>{err2}</span> */}
                          <span style={{ color: "#C63C51" }}>
                            {attachment.length !== 0 && "1 file selected."}
                          </span>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      {/* <Button variant="secondary" onClick={handleClose1}>
                    Close
                  </Button> */}
                      <Button
                        disabled={
                          !attach_status
                            ? true
                            : false || loading_file
                            ? true
                            : false
                        }
                        style={{
                          background: !attach_status ? "#718093" : "#718093",
                          color: "#fff",
                          width: "100%",
                          borderRadius: 10,
                          paddingTop: 10,
                          paddingBottom: 10,
                          border: "none",
                        }}
                        variant="primary"
                        onClick={generateUrl}
                      >
                        Upload
                      </Button>
                    </Modal.Footer>
                  </div>
                </Modal>

                {show2 ? (
                  <ClearChat
                    show={show2}
                    handleClose={() => handleClose2()}
                    name={"Are you sure, you want to clear your history?"}
                    no={() => handleClose2()}
                    yes={() => {
                      // navigate(0);
                      // history.go(0);
                      //  document.location.reload();
                      window.location.reload();
                      window.localStorage.clear();
                      dispatch({
                        type: CLEAR,
                      });
                    }}
                  />
                ) : null}
                {end ? (
                  <ClearChat
                    show={end}
                    // handleClose={() => handleClose2()}
                    name={"Are you sure, you want to end your conversation?"}
                    no={() => setEnd(false)}
                    yes={() => {
                      let a = moment().format("hh:mm a");
                      const thMessage = new ThreadMessage();
                      thMessage.create("Conversation ended.", "menuMsg", "bot");
                      thMessage._MessageTime = a;
                      thMessage._MessageDirection = "left";
                      setThreadMessages((oldArray) => [...oldArray, thMessage]);
                      // navigate("/end_conversation", { replace: true });
                      setGlbEnd(true);
                      setEndflag(true);
                      setEnd(false);
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatThread;

export const createClientMessage = (msg) => {
  const message = "";
};

const style3 = {
  height: "75px",
  alignSelf: "flex-end",
  backgroundColor: "red",
};

import React, { useState, useEffect } from "react";
import "../App.css";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const EndChat = (props) => {
  console.log(props);
  //const [fields, setFields] = useState(["Name","age","company"]);

  const onEnterClick = (e, fldIndex) => {
    // if (e.key === 'Enter') {
    //     console.log('do validate');
    //     console.log("Field Index: ",fldIndex)
    //     console.log(e.target.value);
    //     props.onStateChange(e.target.value);
    //     let temporaryarray = fields.slice();
    //     temporaryarray[fldIndex].fieldValue = e.target.value;
    //     setFields(temporaryarray);
    //     e.target.value='';
    //     if(fieldIndex==(fields.length-1)){
    //         console.log("All fields are captured");
    //         console.log(fields);
    //         setVisibleInputField(false);
    //     }
    //     else{
    //         setFieldIndex(fieldIndex + 1);
    //     }
    // }
  };

  // useEffect(() => {
  //   console.log("menu received:", data);
  //   //updateState("Hi");
  //   // props.callback();
  //   setTimeout(() => {
  //     props.callback();
  //   }, 1000);
  // }, []);

  const onSelect = (item) => {
    //updateState("Hi");
    // props.callback();
    setTimeout(() => {
      props.callback(item.action_item_id);
    }, 1000);
  };

  return (
    <>
      <Modal
        className="attach_modal modal_sec"
        backdrop="static"
        show={props.show}
        onHide={props.handleClose}
      >
        <Modal.Header>
          {/* <Modal.Title>Add Attachment</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="chat_final_pop">
            <Form>
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                {/* <Form.Label>{props.name}</Form.Label> */}

                <div className="chat_menu final_box">
                  <h6>
                    <span key="menu_header">{props.name}</span>
                  </h6>
                  <div className="option">
                    <span
                      disabled={props.disabled}
                      className="indent"
                      onClick={props.yes}
                    >
                      Yes
                    </span>
                    <span className="indent" onClick={props.no}>
                      No
                    </span>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default EndChat;

const style3 = {
  // height: "75px",
  // marginLeft: "-303px",
  // backgroundColor: "red",
};

import React, { useState, useEffect } from "react";
import "../App.css";
import DOMPurify from "dompurify";

const Menu = (props) => {
  let BASE_URL = process.env.REACT_APP_BASE_URL;
  //const [fields, setFields] = useState(["Name","age","company"]);
  const [visibleInputField, setVisibleInputField] = useState(true);
  const data = props.datasource;

  const onSelect = (item) => {
    // setVisibleInputField(false);

    //updateState("Hi");
    // props.callback();
    let session_id = window.localStorage.getItem("session_id");
    let datas = {
      sessionId: session_id,
      menuId: data.id,
      menuSelected: item.id,
    };
    selectMenu(datas);
    // setTimeout(() => {
    props.callback(item);
    //}, 1000);
  };

  const selectMenu = async (datas) => {
    await fetch(`${BASE_URL}chat-bot/user-menu-form-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datas),
    })
      .then((res) => res.json())
      .then((data) => {
        // setMessages([]);
        // loadNextElement();
        setVisibleInputField(false);
      })
      .catch((e) => e.message);
  };

  return (
    <>
      {/* {visibleInputField ? ( */}

      <div
        className="chat_menu"
        // style={{ display: visibleInputField ? "none" : "block" }}
      >
        <h6>
          <span key="menu_header"> {data.content}</span>
        </h6>

        <div className="menu_inn">
          {data.menu_data.map((item, index) => {
            return (
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(item.menu_name),
                }}
                className="indent"
                key={item.id}
                onClick={() => onSelect(item)}
              >
                {/* {item.menu_name} */}
              </span>
            );
          })}
        </div>
      </div>
      {/* ) : null} */}
    </>
  );
};

export default Menu;

const style3 = {
  // height: "75px",
  // marginLeft: "-303px",
  // backgroundColor: "red",
};

import React, { useState, useEffect } from "react";

import "../App.css";
import data from "../data.json";
import Dropdown from "react-bootstrap/Dropdown";
import EmojiPicker from "emoji-picker-react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Attach from "../assets/attach.png";

const InteractiveMenu = (props) => {
  const data = props.datasource;
  let BASE_URL = process.env.REACT_APP_BASE_URL;
  // console.log("Props Interactive=====>", data.form_data);
  //const [fields, setFields] = useState(["Name","age","company"]);
  const [visibleInputField, setVisibleInputField] = useState(true);
  const [show, setShow] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [err, setErr] = useState("");

  const handleClose = () => {
    setShow(false);
    setAttachment([]);
  };
  const handleShow = () => setShow(true);

  const [fields, setFields] = useState("");

  const [fieldIndex, setFieldIndex] = useState(0);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let regName = /\s/g;
  let regPhone = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

  const onEnterClick = (e, fldIndex) => {
    //onSubmit={handleFormSubmit} onDisplay={handleDisplayFormData}
    if (e.key === "Enter") {
      if (!e.target.value) {
        return;
      }

      props.onSubmit(e.target.value);

      setFields("");
    }
  };

  const onTextChange = (e, index) => {
    setFields(e.target.value);
  };

  const onSubmit = () => {
    if (!fields) {
      return;
    }

    props.onSubmit(fields);
    setFields("");
  };

  //   const onSubmit = () => {
  //     if (!fields[fieldIndex].field_values) {
  //       return;
  //     } else if (
  //       fields[fieldIndex].form_field_name == "user_name" &&
  //       regName.test(fields[fieldIndex].field_values)
  //     ) {
  //       return;
  //     } else if (
  //       fields[fieldIndex].form_field_name == "user_email" &&
  //       !regEmail.test(fields[fieldIndex].field_values)
  //     ) {
  //       props.onDisplay(
  //         "Oops! My Bot-brain cannot find the email.",
  //         "form",
  //         "bot"
  //       );
  //       return;
  //     } else if (
  //       fields[fieldIndex].form_field_name == "user_phone" &&
  //       !regPhone.test(fields[fieldIndex].field_values)
  //     ) {
  //       props.onDisplay("Please a provide valid phone number", "form", "bot");
  //       return;
  //     } else {
  //       props.onDisplay(fields[fieldIndex].field_values, "form", "user");

  //       // fields[fieldIndex].field_values = "";

  //       if (fieldIndex == fields.length - 1) {
  //         console.log("All fields are captured");
  //         console.log(fields);
  //         setVisibleInputField(false);
  //         props.onSubmit(fields);
  //       } else {
  //         // fields[fieldIndex].field_values = "";
  //         const nextFieldIndex = fieldIndex + 1;
  //         setFieldIndex(nextFieldIndex);
  //         props.onDisplay(fields[nextFieldIndex].label, "form", "bot");
  //         // fields[fieldIndex].field_values = "";
  //       }
  //     }
  //   };

  return (
    <div className="chat_foot">
      <div className="chat_foot_lt">
        <input
          value={fields}
          type="text"
          // placeholder="Start by typing 'Menu' here"
          placeholder='Start by typing "Menu" here'
          // style={{ display: visibleInputField ? "block" : "none" }}
          //   placeholder={fields[fieldIndex].placeholder}
          onKeyDown={(e) => onEnterClick(e, fieldIndex)}
          onChange={(e) => onTextChange(e, fieldIndex)}
        ></input>
      </div>

      <div className="chat_icons">
        <div className="chat_icon_inner1">
          {/* <span>
            <a>
              <i class="fa-regular fa-face-smile"></i>
            </a>
          </span> */}
          {/* <span onClick={handleShow}>
            <a>
              <i class="fa-solid fa-paperclip"></i>
            </a>
          </span> */}
          <span onClick={onSubmit}>
            <a>
              <i
                style={{
                  color: fields.length == 0 ? "#DDDDDD" : "#527cb2",
                  cursor: !fields.length == 0 && "pointer",
                }}
                class="fa-solid fa-paper-plane"
              ></i>
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default InteractiveMenu;

const style3 = {
  height: "75px",
  marginLeft: "-303px",
  backgroundColor: "red",
};
